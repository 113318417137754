import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators} from 'validate-redux-form';
import {renderField} from '../Utils/renderField';

const validateNumber = value => (value && isNaN(Number(value)) ? 'Solo se permiten datos numericos' : undefined);

const Form = (props) => {
    const { handleSubmit, actualizar, ver } = props;
    return (
        <form onSubmit={handleSubmit} className="uk-card uk-card-default uk-padding uk-margin-auto uk-flex-1 uk-flex-center">
            <div className="uk-width-1-3@m uk-margin-auto">
            <label>Nombre</label>
            <Field className="uk-input uk-border-rounded" component={renderField} name="name" disabled={ver} />
            <br />
            <br />
            <label>Porcentaje de comision</label>
            <Field className="uk-input uk-border-rounded" component={renderField} name="commission_percentage" disabled={ver} validate={validateNumber} type="number" />
            </div>
            <br />
            <br />
            <div className="uk-width-1-3@m uk-margin-auto uk-flex uk-flex-between">
           
            <a
                 className="uk-button uk-button-secondary uk-border-rounded uk-button-small uk-flex"
                href="/#/sales_channel"
            >
                Cancelar
                <i style={{marginLeft: "2px"}} className="material-icons">cancel</i>
            </a>
            {!ver
            && (
                <button
                    type="submit"
                    className="uk-button uk-button-primary uk-border-rounded uk-button-small uk-margin-small-right uk-flex"
                >
                    {actualizar ? 'Actualizar ' : 'Registrar '}
                    <i style={{marginLeft: "2px"}} className="material-icons">save</i>
                </button>
            )
            }
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'SalesChannelForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            name: validators.exists()('Campo es requerido'),
            commission_percentage: validators.exists()('Campo es requerido'),
        });
    },
})(Form);
