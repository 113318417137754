import React, { Component } from 'react'
import EditorComponent from './Editor';
import { Cards } from './CardResumen';
import { GridLeads } from './GridLeads';
import Example from './Date';


export default class ReportsWeek extends Component {

    componentWillUnmount = ()=> {
        const { resetVariables } = this.props
        
        resetVariables()
    }
    render() {
        const { data_lead, data_possibility, cargarInformacion, total_lead, registrarNoticia,
                total_possibility, meta_anual, meta_mensual, venta_semanal, text, handleChange, 
                setCalendar, listPageLeads, listPageLeadsPossibility,
                mostrar_calendar,  page, loader_lead, page_posibility  } = this.props;

        return (
            <div className="uk-margin-bottom">
                <br />
                <h3 className="uk-text-bold uk-text-lead">Reporte Semanal</h3>
                <div  className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">

                    <button
                        className="uk-button uk-button-primary uk-border-rounded
                         uk-button-small uk-margin-small-top uk-flex"
                        onClick={ ()=>{
                            setCalendar( !mostrar_calendar )
                        }}
                    >
                        <i className="material-icons" style={{marginRight: "4px"}} >filter_list</i>
                        { mostrar_calendar ? 'Ocultar Calendario' : 'Mostrar Calendario' }
                    </button>

                    <Example 
                        mostrar={ mostrar_calendar }
                        cargarInformacion={cargarInformacion}
                    />

                    <Cards 
                        meta_anual={ meta_anual }
                        meta_mensual={ meta_mensual }
                        venta_semanal={ venta_semanal }
                    />
                   
                   <GridLeads 
                     data_lead={data_lead}
                     total_lead={total_lead}
                     listPageLeads={ listPageLeads }
                     loader_lead={ loader_lead }
                     page={page}
                     data_possibility={ data_possibility }
                     total_possibility={total_possibility}
                     listPageLeadsPossibility={ listPageLeadsPossibility}
                     page_posibility={ page_posibility }
                   />

                    <div className="uk-margin-medium-bottom uk-margin-medium-top">

                        <h5 className="uk-padding-small">Buenas Noticias</h5>
                        <EditorComponent 
                            registrarNoticia={ registrarNoticia }
                            textWeek ={ text }
                            handleChange = { handleChange }
                        />
                    </div>


                </div>

            </div>
        )
    }
}
