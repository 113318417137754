import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { SelectField } from '../Utils/renderField';

//Form para seleccionar el Embudo de ventas y se muestren sus leads
const SelectFieldReport = (props) => {
    const { handleSubmit, funnelOptions, loadingFunnel } = props;
    return (
        <form
            onSubmit={handleSubmit}
            className="uk-flex uk-width-1-3@m uk-width-1-2"
        >
            <div className="uk-width-1-1 ">
                <Field
                    name="embudo"
                    placeholder="Seleccionar Embudo..."
                    options={funnelOptions}
                    component={SelectField}
                    onChange={e => loadingFunnel(e)}
                />
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'reportForm',
})(SelectFieldReport);