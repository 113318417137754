/* Actions of Industry  */

import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import {reset} from 'redux-form';
import { get } from 'lodash';

// Constantes

const LOADER = 'REPORTS_LOADER';
const SET_DATA = 'SET_DATA_R';
const SET_EMBUDO_LEAD = 'SET_EMBUDO_LEAD'
const PAGE = 'SET_PAGE_R';
const OPEN_SIDEBAR = 'OPEN_SIDEBAR_R';
const FUNNEL_OPTIONS = 'FUNNEL_OPTIONS_R';
const SET_STATS = 'SET_STATS'
const QUANTITY_STAGES = 'QUANTITY_STAGES'

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: SET_DATA,
    data,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setSidebar = stateSidebar => ({
    type: OPEN_SIDEBAR,
    stateSidebar,
});

const setFunnelOptions = funnelOptions => ({
    type: FUNNEL_OPTIONS,
    funnelOptions,
});

const setStats = stats => ({
    type: SET_STATS,
    stats,
});

const setStages = quantity => ({
    type: QUANTITY_STAGES,
    quantity,
});

const setEmbudo = embudo => ({
    type: SET_EMBUDO_LEAD,
    embudo,
});


// ------------------------------------
// Funciones
// ------------------------------------

// Setea en el estado el id del embudo
const loadingFunnel = (embudoId) => (dispatch, getStore) => {
    dispatch(setEmbudo(embudoId.value))
    dispatch(listLeads(embudoId.value))

}

// Lista los Leads segun el embudo seleccionado
const listLeads = (embudoId) => (dispatch, getStore) => {

    const { values } = getStore().form.filterFormReport;
    const data ={
        embudoId
    }

    if (values != undefined){

        if(values.user)
        {
            const user_id = values.user.value;
            data.user_id=user_id;
        }

        if(values.sales_channel)
        {
            const canal_id = values.sales_channel.value;
            data.canal_id=canal_id;
        }

        if (values.data_initial && values.data_finish && values.Date) {
            data.data_initial = values.data_initial;
            data.data_finish = values.data_finish;
            data.option = values.Date
        }
    }
    dispatch(setLoader(true))

    api.get(`leads/filterFunnel`, data).then((response) => {
        console.log(response)
        dispatch(setData(response))
    })

    api.post(`leads/quantityStages`, embudoId).then((response) => {
        dispatch(setStages(response))
    })

    api.post(`leads/statsReports`, data).then((response) => {
        dispatch(setStats(response))
    })
    .catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 3000);
    })
    .finally(() => {
        dispatch(setLoader(false));
    });

    dispatch(reset('filterFormReport'))
    dispatch(setSidebar(false));
}

// Lista los leads y aplica paginacion 
const listPageLeads = page  => (dispatch, getStore) => {
    
    let { embudo } = getStore().reportLeads
    const { values } = getStore().form.filterFormReport;
    
    const data = { 
        embudoId: embudo,
        page
    }
    
    if (values != undefined){

        if(values.user)
        {
            const user_id = values.user.value;
            data.user_id=user_id;
        }

        if(values.sales_channel)
        {
            const canal_id = values.sales_channel.value;
            data.canal_id=canal_id;
        }

        if (values.data_initial && values.data_finish && values.Date) {
            data.data_initial = values.data_initial;
            data.data_finish = values.data_finish;
            data.option = values.Date
        }
    }

    dispatch(setLoader(true))

    api.get(`leads/filterFunnel`, data).then((response) => {
       
        dispatch(setData(response))
        dispatch(setPage(page));
    })
    .catch((error) => {
        console.log(error)
    })
    .finally(() => {
        dispatch(setLoader(false));
    }); 

    
};

// Abre el sidebar de filtros
const openSidebar = () => (dispatch) => {
    dispatch(setSidebar(true));
};

// Cierra el sidebar de filtros
const closeSidebar = () => (dispatch) => {
    dispatch(setSidebar(false));
};


// Obtiene los embudos de venta
const getSalesFunnel = () => (dispatch) => {
    api.get('sales-funnel/funnels_list')
        .then((response) => {
            const options = response.map(item => ({
                value: item.id,
                label: item.name,
            }));
            dispatch(setFunnelOptions(options));
        })
        .catch((error) => {
            NotificationManager.error(error, 'ERROR', 3000);
        })
        .finally(() => {
        });
};

//Obtiene los usuarios
const getUsers = () => () => {
    const users = [];

    return api.get('user').then((response) => {
        response.results.forEach((user) => {
            users.push({ value: user.id, label: user.username });
        });
        return users;
    })
        .catch((err) => {
            return users;
        });
};

// Obtiene los canales de venta
const getChannels = () => () => {
    const channels = [];

    return api.get('sales_channel').then((response) => {
        response.results.forEach((channel) => {
            channels.push({ value: channel.id, label: channel.name });
        });
        return channels;
    })
        .catch((err) => {
            return channels;
        });
};

// Resetea los estados seleccionados
const resetValues = () => (dispatch) => {
    dispatch(setData({}))
    dispatch(setStages([]))
    dispatch(setStats([]))
}

// Accciones

export const actions = {
    loadingFunnel,
    openSidebar,
    closeSidebar,
    getSalesFunnel,
    listLeads,
    listPageLeads,
    getUsers,
    getChannels,
    resetValues,
};


export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_EMBUDO_LEAD]: (state, { embudo }) => {
        return {
            ...state,
            embudo,
        };
    },
    [SET_DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },

    [FUNNEL_OPTIONS]: (state, { funnelOptions }) => {
        return {
            ...state,
            funnelOptions,
        };
    },

    [OPEN_SIDEBAR]: (state, { stateSidebar }) => {
        return {
            ...state,
            stateSidebar,
        };
    },
    [SET_STATS]: (state, { stats }) => {
        return {
            ...state,
            stats,
        };
    },
    [QUANTITY_STAGES]: (state, { quantity }) => {
        return {
            ...state,
            quantity,
        };
    },
};

// Inicializa los estados
export const initialState = {
    loader: false,
    funnelOptions: [],
    data: {},
    page: 1,
    stateSidebar: false,
    stats: [],
    quantity: [],
    embudo: 0,
};

export default handleActions(reducers, initialState);
