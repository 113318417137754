import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/businessLines/businessLines';
import CreateBusinessLines from './CreateBusinessL';


const ms2p = (state) => {
    return {
        ...state.businessLines,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CreateBusinessLines);
