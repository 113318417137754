import {combineReducers} from 'redux';
import {routerReducer as routing} from 'react-router-redux';
import {reducer as formReducer} from 'redux-form';
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
// eslint-disable-next-line camelcase
import reset_password from './modules/reset_password/reset_password';
import newPassword from './modules/cuenta/newPassword';
import businessLines from "./modules/businessLines/businessLines";
import changeCoin from './modules/configurations/ChangeType/ChangeCoin';
import sales_channel from './modules/sales_channel/sales_channel';
import industry from './modules/industry/industry';
import salesFunnel from './modules/salesFunnel/salesFunnel';
import stageSale from './modules/stageSale/stageSale';
import changePwd from './modules/cuenta/changePassword';
import annualGoals from './modules/annualGoals/annualGoals';
import users from './modules/users/users';
import gestionLeads from './modules/GestionLeads/GestionLeads';
import contact from './modules/contacts/contacts';
import company from './modules/company/company';
import lead from './modules/GestionLeads/registerlead'
import reportLeads from "./modules/reports/reportLeads"
import reportWeek from "./modules/reports/reportWeek"
import dashboard from "./modules/dashboard/dashboard"; 

export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    usuarios,
    routing,
    notificaciones,
    reset_password,
    newPassword,
    sales_channel,
    changeCoin,
    businessLines,
    industry,
    salesFunnel,
    stageSale,
    changePwd,
    annualGoals,
    users,
    gestionLeads,
    contact,
    company,
    lead,
    reportLeads,
    reportWeek,
    dashboard,
});
