import { handleActions } from 'redux-actions';
import { api } from "api";
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";

const SET_ETAPAS = 'SET_ETAPAS';

const SUBMIT = 'SALESFUNNEL_SUBMIT';
const LOADER = 'SALESFUNNEL_LOADER';
const SET_DATASF = 'SET_DATASF';
const PAGE = 'SET_PAGE';
const SHOW_FORM_BL = 'SHOW_FORM_BL';
const SET_LINE = 'SET_LINE';
const OPEN_MODAL_LINE = 'OPEN_MODAL_LINE';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: SET_DATASF,
    data,
});

const setPage = page => ({
    type: PAGE,
    page,
});

export const setEtapas = etapas => ({
    type: SET_ETAPAS,
    etapas,
});

const setModal = stateModal => ({
    type: OPEN_MODAL_LINE,
    stateModal,
});
const agregarEtapa = () => (dispatch, getStore) => {
    const { values } = getStore().form.salesFunnelForm;
    const { etapas } = getStore().salesFunnel;
    const newForm = {
        ...values,
        stage: "",
        percentage: "",
    };

    const id = new Date().getTime();
    const nuevaEtapa = [{ id, name: values.stage, percentage: values.percentage }, ...etapas];
    dispatch(setEtapas(nuevaEtapa));
    console.log(nuevaEtapa);
    dispatch(initializeForm('salesFunnelForm', newForm));
};

const handleChangeLine = valor => (dispatch, getStore) => {
    const {values} = getStore().form.salesFunnelForm;
    const newForm = {
        ...values,
        percentage_line: valor,
    };
    dispatch(initializeForm('salesFunnelForm', newForm));
};

const handleChangeItems = items => (dispatch) => {
    dispatch(setEtapas(items));
};

const removeItem = id => (dispatch, getStore) => {
    const { etapas } = getStore().salesFunnel;
    const eliminado = etapas.filter(item => item.id === id);
    const result = etapas.filter(item => item.id !== id);
    
    if (eliminado[0].eliminar){
        api.eliminar(`stage-sale/${id}`).then(() => {
            NotificationManager.success('Etapa eliminada correctamente', 'Éxito', 3000);
        }).catch((error) => {
            NotificationManager.error('Hubo error en la eliminación', 'ERROR', 0);
        });
    }

    
    dispatch(setEtapas(result));
};

const onSubmit = () => (dispatch, getStore) => {
    const { values } = getStore().form.salesFunnelForm;
    const { etapas } = getStore().salesFunnel;
    const data = Object.assign(values, { etapas });
    if (etapas.length === 0) {
        NotificationManager.error('No tiene etapas asignada al Embudo', 'Error', 3000);
    } else {
        api.post('sales-funnel', data).then((response) => {
            NotificationManager.success('Linea de Negocio registrada correctamente', 'Éxito', 1000);
            dispatch(setEtapas([]));
            dispatch(push("/sales-funnel"));
        }).catch((error) => {
            NotificationManager.error(error.detail, 'ERROR', 3000);
        }).finally(() => {

        });
    }
};

const limpiar = () => (dispatch) => {
    const etapas = [{id: 1, name: "Ganado", percentage: 100},
        {id: 2, name: "Perdido", percentage: 0}];
    dispatch(setEtapas(etapas));
};

const detalle = id => (dispatch) => {
    api.get(`sales-funnel/${id}`).then((response) => {
        const etapas = {};
        response.stages_sales.forEach((item) => { etapas[item.id] = {...item, eliminar: true}; });
        const etapasOrdenadas = response.ordering.map(orden => (etapas[orden]));

        console.log(etapasOrdenadas);
        dispatch(initializeForm('salesFunnelForm', response));
        dispatch(setEtapas(etapasOrdenadas));
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const filterSalesFunnel = search => (dispatch) => {
    dispatch(setLoader(true));

    api.get(`sales-funnel/search/${search}`).then((response) => {
        dispatch(setData(response));
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listar = (page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    const params = { page };

    api.get('sales-funnel', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    api.eliminar(`sales-funnel/${id}`).then(() => {
        NotificationManager.success('Embdudo de Venta eliminado correctamente', 'Éxito', 3000);
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error('Hubo error en la eliminación', 'ERROR', 0);
    });
};

const actualizar = () => (dispatch, getStore) => {
    const { values } = getStore().form.salesFunnelForm;
    const { etapas } = getStore().salesFunnel;
    const data = Object.assign(values, { etapas });

    api.put(`sales-funnel/${values.id}`, data).then(() => {
        NotificationManager.success('Embudo de Venta se actualizó correctamente', 'Éxito', 3000);
        dispatch(setEtapas([]));
        dispatch(push('/sales-funnel'));
    }).catch(() => {
        NotificationManager.error('Hubo error en la actualización', 'ERROR', 0);
    });
};

const getLines = search => () => {
    const businessLines = [];
    return api.get('business-lines', { search }).then((response) => {
        response.results.forEach((lifecycle) => {
            console.log(lifecycle);
            businessLines.push({ value: lifecycle.id, label: lifecycle.name, percentage: lifecycle.percentage_line });
        });

        return businessLines;
    })
        .catch((err) => {
            return [];
        });
};


const getSales = search => () => {
    const salesChannels = [];

    return api.get('sales_channel', { search }).then((response) => {
        response.results.forEach((lifecycle) => {
            salesChannels.push({ value: lifecycle.id, label: lifecycle.name });
        });

        return salesChannels;
    })
        .catch((err) => {
            return [];
        });
};

const registrarLineaNegocio = () => (dispatch, getState) => {
    const formData = getState().form.businessLines.values;
    api.post('business-lines', formData).then((response) => {
        NotificationManager.success('Linea de Negocio registrada correctamente', 'Éxito', 1000);
        dispatch(setModal(false));
        const nuevo_elemento = {value: response.id, label: response.name};
        let formValues = getState().form.salesFunnelForm.values;
        formValues = formValues || {};
        formValues.business_lines = nuevo_elemento;
        formValues.percentage_line = 0;
        dispatch(initializeForm('salesFunnelForm', formValues));
    }).catch((error) => {
        NotificationManager.error('Verifica si el nombre no se repite', 'ERROR', 3000);
    }).finally(() => {

    });
};

const openModal = () => (dispatch) => {
    dispatch(setModal(true));
};
const closeModal = () => (dispatch) => {
    dispatch(setModal(false));
};

export const actions = {
    onSubmit,
    listar,
    eliminar,
    detalle,
    actualizar,
    filterSalesFunnel,
    agregarEtapa,
    handleChangeItems,
    removeItem,
    limpiar,
    handleChangeLine,
    registrarLineaNegocio,
    openModal,
    closeModal,
    getLines,
    getSales,
};


export const reducers = {
    [SET_ETAPAS]: (state, { etapas }) => {
        return {
            ...state,
            etapas,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_DATASF]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SHOW_FORM_BL]: (state, { show_form }) => {
        return {
            ...state,
            show_form,
        };
    },
    [SET_LINE]: (state, { business_lines }) => {
        return {
            ...state,
            business_lines,
        };
    },
    [OPEN_MODAL_LINE]: (state, { stateModal }) => {
        return {
            ...state,
            stateModal,
        };
    },
};


export const initialState = {
    etapas: [],
    loader: false,
    data: {},
    page: 1,
    line: null,
    stateModal: false,
};
export default handleActions(reducers, initialState);
