import { connect } from 'react-redux'
import { actions } from  '../../../redux/modules/contacts/contacts'

import Contacts from './Contacts'
import ListContacts from './ListContacts'


const ms2p = (state) => {
    return {
      ...state.contact,
    };
  };
  
  const md2p = { ...actions };

  

//===========================
// Conection List Contacts
//===========================
const listContacts = connect(ms2p, md2p)(ListContacts);
  
//==========================
// Conection Create Contact
//=========================?
const createContact = connect(ms2p, md2p)(Contacts);


export const contact = {
    listContacts,
    createContact
  }