import { push } from 'react-router-redux';
import { api } from 'api';
import { NotificationManager } from 'react-notifications';
import { handleActions } from 'redux-actions';
import { initialize } from 'redux-form';
import moment from 'moment';
import {
    setEmbudo, detalle as detalleLead,
    resetProperties, eliminar as eliminarLead
} from './registerlead';

import { reset } from 'redux-form';

const LOADER = 'DASHBOARD_LOADER';
const DATA = 'DASHBOARD_DATA';
const ITEM = 'DASHBOARD_ITEM';
const LEADS = 'DASHBOARD_LEADS';
const COLUMNS = 'DASHBOARD_COLUMNS';
const COLUMNS_ORDERING = 'DASHBOARD_COLUMNS_ORDERING';
const STATE_MODAL = 'STATE_MODAL';
const FUNNEL_OPTIONS = 'SALES_FUNNEL_OPTIONS';

const OPEN_SIDEBAR = 'OPEN_SIDEBAR';

//-----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setItem = item => ({
    type: ITEM,
    item,
});
const setLeads = leads => ({
    type: LEADS,
    leads,
});
const setColumns = columns => ({
    type: COLUMNS,
    columns,
});

const setColumnsOrdering = columnsOrdering => ({
    type: COLUMNS_ORDERING,
    columnsOrdering,
});
const setSidebar = stateSidebar => ({
    type: OPEN_SIDEBAR,
    stateSidebar,
});

const stateModal = stateModal => ({
    type: STATE_MODAL,
    stateModal,
});

const setFunnelOptions = funnelOptions => ({
    type: FUNNEL_OPTIONS,
    funnelOptions,
});

// -----------------------------------
// Actions
// -----------------------------------

const onDragEnd = result => (dispatch, getState) => {
    const { source, destination } = result;
    console.log(result);
    // dropped outside the list
    if (!destination) {
        return;
    }
    // get the start and the finihs column
    const startColumn = getState().gestionLeads.columns[
        source.droppableId
    ];
    const finishColumn = getState().gestionLeads.columns[
        destination.droppableId
    ];

    if (startColumn === finishColumn) {
        const column = getState().gestionLeads.columns[
            source.droppableId
        ];
        const newLeadsOrdering = Array.from(column.leads_ordering);
        const [removed] = newLeadsOrdering.splice(source.index, 1);
        newLeadsOrdering.splice(destination.index, 0, removed);
        const newColumn = {
            ...column,
            leads_ordering: newLeadsOrdering,
        };

        const newColumnsState = {
            ...getState().gestionLeads.columns,
            [newColumn.id]: newColumn,
        };

        api.post('lead-management/move_leads', newColumn).then(
            () => { }
        ).catch(() => {
            NotificationManager.error('Hubo un error al guardar el ultimo movimiento del lead', 'Error', 2000);
        });
        dispatch(setColumns(newColumnsState));
    } else {
        const newLeadsOrdering = Array.from(startColumn.leads_ordering);
        const [removed] = newLeadsOrdering.splice(source.index, 1);
        const newStartColumn = {
            ...startColumn,
            leads_ordering: newLeadsOrdering,
            count: newLeadsOrdering.length,
        };
        const newFinishLeadsOrdering = Array.from(finishColumn.leads_ordering);
        newFinishLeadsOrdering.splice(destination.index, 0, removed);
        const newFinishColumn = {
            ...finishColumn,
            leads_ordering: newFinishLeadsOrdering,
            count: newFinishLeadsOrdering.length,
        };

        const newColumnsState = {
            ...getState().gestionLeads.columns,
            [newStartColumn.id]: newStartColumn,
            [newFinishColumn.id]: newFinishColumn,
        };
        const data = { start_stage: newStartColumn, finish_stage: newFinishColumn, lead: removed };
        api.post('lead-management/move_leads_stages', data).then(
            () => { }
        ).catch(() => {
            NotificationManager.error('Hubo un error al guardar el ultimo movimiento del lead', 'Error', 2000);
        });
        dispatch(setColumns(newColumnsState));
    }
};

const detalle = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`lead-management/${id}`)
        .then((response) => {
            const columns = {};
            const leads = {};
            const columnsOrdering = [...response.ordering];
            response.leads.forEach((lead) => {
                leads[lead.id] = { ...lead };
            });
            response.stages_sales.forEach((item) => {
                console.log(response.stages_sales)
                columns[item.id] = {
                    ...item,
                    leads_ordering: JSON.parse(item.leads_ordering),
                    count: JSON.parse(item.leads_ordering).length,
                };
            });
            console.log(response);
            dispatch(setLeads(leads));
            dispatch(setColumnsOrdering(columnsOrdering));
            dispatch(setColumns(columns));
            dispatch(setEmbudo({ id }));
        })
        .catch((error) => {
            NotificationManager.error(error.detail, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getSalesFunnel = () => (dispatch) => {
    api.get('sales-funnel/funnels_list')
        .then((response) => {
            const options = response.map(item => ({
                value: item.id,
                label: item.name,
            }));
            dispatch(setFunnelOptions(options));
            dispatch(detalle(1));
        })
        .catch((error) => {
            NotificationManager.error(error, 'ERROR', 3000);
        })
        .finally(() => {
        });
};
const openSidebar = () => (dispatch) => {
    dispatch(setSidebar(true));
};
const closeSidebar = () => (dispatch) => {
    dispatch(setSidebar(false));
};
const getUsers = () => (dispatch) => {
    const users = [];

    return api.get('user').then((response) => {
        response.results.forEach((user) => {
            users.push({ value: user.id, label: user.username });
        });
        return users;
    })
        .catch((err) => {
            return users;
        });
};

const filterUser = () => (dispatch, getStore) => {
    const { embudo } = getStore().lead;
    const { values } = getStore().form.filterForm;
    let data = {}
    const embudo_id = embudo.id;
    console.log("filtros: ", values)
    data.embudo_id = embudo_id;
    if (values.user) {
        const user_id = values.user.value;
        console.log(embudo_id);
        console.log(user_id);
        data.user_id = user_id;

    }

    const fecha = moment(values.date_finish).add(1, 'days');
    const format = moment(fecha).format("YYYY-MM-DD HH:mm:mm")

    if (values.date_initial && values.date_finish && values.Date) {
        data.date_initial = values.date_initial;
        data.date_finish = format;
        data.option = values.Date
    }
    api.post('lead-management/filtros', data).then((response) => {
        const leads = {};

        response.leads.forEach((lead) => {
            leads[lead.id] = { ...lead };
        });
        console.log(response)
        dispatch(setLeads(leads));
        dispatch(setSidebar(false));
        dispatch(reset('filterForm'))

    });
};


// ===================================
//      Code of Modal
// ===================================
const openModal = () => (dispatch) => {

    dispatch(stateModal(true));
};
const closeModal = () => (dispatch) => {

    dispatch(resetProperties())
    dispatch(stateModal(false));
};
export const actions = {
    onDragEnd,
    openModal,
    closeModal,
    detalle,
    getSalesFunnel,
    closeSidebar,
    getUsers,
    openSidebar,
    filterUser,
    detalleLead,
    eliminarLead
};

// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [LEADS]: (state, { leads }) => {
        return {
            ...state,
            leads,
        };
    },
    [COLUMNS]: (state, { columns }) => {
        return {
            ...state,
            columns,
        };
    },
    [COLUMNS_ORDERING]: (state, { columnsOrdering }) => {
        return {
            ...state,
            columnsOrdering,
        };
    },
    [STATE_MODAL]: (state, { stateModal }) => {
        return {
            ...state,
            stateModal,
        };
    },
    [FUNNEL_OPTIONS]: (state, { funnelOptions }) => {
        return {
            ...state,
            funnelOptions,
        };
    },
    [OPEN_SIDEBAR]: (state, { stateSidebar }) => {
        return {
            ...state,
            stateSidebar,
        };
    },
};

const initialState = {
    loader: false,
    stateModal: false,
    funnelOptions: [],
    data: {
        results: [],
        count: 0,
    },
    stateSidebar: false,
    item: {},
    leads: {},
    columns: {
    },
    columnsOrdering: [],
};

export default handleActions(reducers, initialState);
