import { connect} from 'react-redux';
import { actions } from '../../../redux/modules/reports/reportLeads';
import Leads from './LeadsList';

// Conexion entre react y redux
const ms2p = (state) => {
    return {
        ...state.reportLeads,
    };
};

const md2p = {...actions };


export default connect(ms2p, md2p)(Leads);
