import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Grid from '../Utils/Grid';
import { standardActions } from '../Utils/Grid/StandardActions';
import 'uikit/dist/js/uikit-icons';

export default class AnnualGoalsList extends Component {
    componentWillMount = () => {
        const { listar } = this.props;
        listar();
    };

    render() {
        const {
            data,
            loader,
            onSortChange,
            eliminar,
            listar,
            page,
        } = this.props;
        return (
            <React.Fragment>
                <br />
                <h3 className="uk-text-bold uk-text-lead uk-width-1-2">
                    Metas Anuales
                </h3>
                <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">
                    <div className="uk-flex uk-flex-right uk-padding uk-padding-remove-bottom uk-margin-auto-top@s">
                        <Link
                            className="uk-button
                            uk-button-primary
                            uk-border-rounded
                            uk-button-small
                            uk-flex"
                            to="/annual-goals/create"
                        >
                            Agregar

                            <i style={{marginLeft: "2px"}} className="material-icons">add_circle_outline</i>
                        </Link>
                    </div>

                    <div className="uk-card uk-padding">
                        <Grid
                            data={data}
                            loading={loader}
                            onPageChange={listar}
                            onSortChange={onSortChange}
                            page={page}
                        >
                            <TableHeaderColumn
                                dataField="annual_goal"
                                dataSort
                            >
                                Año
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="goal_quetzales"
                                dataSort
                                dataFormat={cell => `Q. ${cell.toFixed(2)}`
                                }
                            >
                                Meta en quetzales
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="is_active_goal"
                                dataSort
                                dataFormat={cell => (cell ? 'Activa' : 'No Activa')
                                }
                            >
                                Estado de meta
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    editar: 'annual-goals',
                                    ver: 'annual-goals',
                                    eliminar,
                                })}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
