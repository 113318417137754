import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { SelectField } from '../Utils/renderField';


const SelectFieldDashboard = (props) => {
    const { handleSubmit, funnelOptions, detalle } = props;
    return (
        <form
            onSubmit={handleSubmit}
            className="uk-flex uk-width-1-3@m uk-width-1-2"
        >
            <div className="uk-width-1-1 ">
                <Field
                    name="embudo"
                    placeholder="Seleccionar Embudo..."
                    options={funnelOptions}
                    component={SelectField}
                    onChange={e => detalle(e.value)}
                />
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'DashboardForm',
})(SelectFieldDashboard);