import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import { Link } from 'react-router-dom';
import Grid from "../Utils/Grid";
import {standardActions} from "../Utils/Grid/StandardActions";
import ListadoSalesChannelContainer from './ListadoSalesChannelContainer';

class ListadoSalesChannel extends Component {
    componentWillMount = () => {
        this.props.listar();
    }


    render() {
        const {data, loader, searchChange, onPageChange, onSortChange, eliminar, page, listar} = this.props;

        return (
            <div>
                <br />
                <h3 className="uk-text-bold uk-text-lead uk-width-1-2">
                    Canales de ventas
                </h3>

                <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">

                    <div className="uk-flex uk-flex-between uk-padding uk-padding-remove-bottom uk-margin-auto-top@s">
                        <input
                            type="text"
                            className="uk-input uk-border-rounded uk-width-1-5"
                            onChange={e => searchChange(e.target.value)
                            }
                            placeholder="Buscar..."
                        />

                        <Link
                            className="uk-button uk-button-primary uk-border-rounded uk-button-small uk-margin-small-bottom uk-flex"
                            to="/sales_channel/create"
                        >
                            Agregar
                            <i style={{marginLeft: "2px"}} className="material-icons">add_circle_outline</i>
                        </Link>
                    </div>

                    <Grid
                        className="uk-card uk-padding"
                        data={data}
                        loading={loader}
                        onPageChange={listar}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="name"
                            dataSort
                        >
                            Nombre
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="commission_percentage"
                            dataSort
                        >
                            Porcentaje de comision
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: "sales_channel", ver: "sales_channel", eliminar })}
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default ListadoSalesChannel;
