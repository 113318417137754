import React, { Component } from 'react';
import Grid from '../Utils/Grid';
import {standardActions} from '../Utils/Grid/StandardActions';

export default class ListIndustries extends Component {
    componentWillMount = () => {
        const { listar } = this.props;

        listar();
    }

    handleSearch = (e) => {
        const { listar, filterIndustry } = this.props;

        if (e.target.value != '') {
            filterIndustry(e.target.value);
        } else {
            listar();
        }
    }

    render() {
        const {data, loader, onSortChange, eliminar, listar, page } = this.props;
        return (
            <React.Fragment>
                <br />
                <h3 className="uk-text-lead uk-text-bold">Lista Industrias</h3>

                <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">
                    <div className="uk-flex uk-flex-between uk-padding uk-padding-remove-bottom uk-margin-auto-top@s">
                        <input
                            className="uk-input uk-border-rounded uk-width-1-5"
                            placeholder="Buscar ..."
                            onChange={this.handleSearch}
                        />
                        <a
                            className="uk-button uk-button-primary uk-border-rounded uk-margin-small-bottom uk-button-small uk-flex"
                            href="/#/industry/create"
                        >

                            <i style={{marginRight: "4px"}} className="material-icons">add_circle_outline</i>
                            Agregar
                        </a>
                    </div>

                    <Grid
                        className="uk-padding"
                        data={data}
                        loading={loader}
                        onPageChange={listar}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="name"
                            dataSort
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: "industry", ver: "industry", eliminar })}
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}
