import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SET_DATABL = 'SET_DATABL';
const SET_LOADER = 'SET_LOADER';
const SET_REGISTER = 'SET_REGISTER';
const SET_PAGE_BL = 'SET_PAGE_BL';


const listarLinea = ( page = 1) => (dispatch) => {

    dispatch({type: SET_LOADER, loader: true});
    const params = { page }
    api.get('business-lines', params).then((response)=>{
        dispatch({type: SET_DATABL, data: response});
        dispatch({type: SET_PAGE_BL, page: page});
    }).catch(() => {
    }).finally(()=>{
        dispatch({type: SET_LOADER, loader: false});
    });
};

export const editarLinea = (id) => (dispatch, getStore) => {
    dispatch({type: SET_LOADER, loader: true});
    api.get(`business-lines/${id}`).then((response)=>{
        dispatch(initializeForm('businessLines', response));
        dispatch({type: SET_REGISTER, register: response});
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    }).finally(()=>{
        dispatch({type: SET_LOADER, loader: false});
    })
}



const registrarLinea = () => (dispatch, getStore) => {
    const estado = getStore();
    const formData = estado.form.businessLines.values;

    api.post('business-lines', formData).then((response) => {
        console.log(response)
        NotificationManager.success('Linea de Negocio registrada correctamente', 'Éxito', 1000);
        dispatch(push("/business-lines"));
    }).catch((error) => {
        NotificationManager.error('Verifica si el nombre no se repite', 'ERROR', 0);
    }).finally(() => {

    });
}

const actualizarLinea = () => (dispatch, getStore) => {
    const estado = getStore();
    const formData = estado.form.businessLines.values;

    api.put(`business-lines/${formData.id}`, formData).then((response) => {
        NotificationManager.success('Linea de Negocio actualizada correctamente', 'Éxito', 1000);
        dispatch(push("/business-lines"));
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    }).finally(() => {

    });
}

const eliminarLinea = (id) => (dispatch, getStore) => {
    api.eliminar(`business-lines/${id}`).then((response) => {
        NotificationManager.success('Linea de Negocio eliminada correctamente', 'Éxito', 1000);
        dispatch(listarLinea());
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    }).finally(() => {

    });
}

const filterBusiness = (search) => (dispatch) => {
    dispatch({type: SET_LOADER, loader: true});
    api.get(`business-lines/search/${search}`).then((response)=> {
        dispatch({type: SET_DATABL, data: response});
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    }).finally(() => {
        dispatch({type: SET_LOADER, loader: false});
    })
}

export const actions = {
    registrarLinea,
    actualizarLinea,
    eliminarLinea,
    listarLinea,
    editarLinea,
    filterBusiness,
};

export const reducers = {
    [SET_DATABL]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },

    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },

    [SET_REGISTER]: (state, { register }) => {
        return {
            ...state,
            register,
        };
    },

    [SET_PAGE_BL]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    register: null,
    page: 1,
};

export default handleActions(reducers, initialState);
