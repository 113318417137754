
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = 'LOADER';
const FUNNEL_OPTIONS = 'FUNNEL_OPTIONS';
const SET_DATA = 'SET_DATA';
const COLUMNS = 'DASHBOARD_COLUMNS';
// ------------------------------------
// Pure Actions
// ------------------------------------

const setFunnelOptions = funnelOptions => ({
    type: FUNNEL_OPTIONS,
    funnelOptions,
});
const setData = data => ({
    type: SET_DATA,
    data,
});
export const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setColumns = columns => ({
    type: COLUMNS,
    columns,
});
// ------------------------------------
// Actions
// ------------------------------------
const detalle = id => (dispatch, getState) => {
    dispatch(setLoader(true));
   let leads=[];
    api.post(`leads/filterFunnel`, id).then((response) => {

        response.results.forEach((item)=>{
            leads.push({
                estado: item.business_stage.label,
                monto: item.quetzal
            })
        })
        dispatch(setData(leads))
  
    })
    api.get(`lead-management/${id}`)
        .then((response) => {
            const columns = {};
            response.stages_sales.forEach((item) => {
                columns[item.id] = {
                    ...item,
                    name: item.name,
                    count: JSON.parse(item.leads_ordering).length,
                };
            });
            dispatch(setColumns(columns));
       
        })
        .catch((error) => {
            NotificationManager.error(error.detail, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const getSalesFunnel = () => (dispatch) => {
    api.get('sales-funnel/funnels_list')
        .then((response) => {
            const options = response.map(item => ({
                value: item.id,
                label: item.name,
            }));
            dispatch(setFunnelOptions(options));
        })
        
        .catch((error) => {
            NotificationManager.error(error, 'ERROR', 3000);
        })
        .finally(() => {
        });
};


export const actions = {
    getSalesFunnel,
    detalle,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [FUNNEL_OPTIONS]: (state, { funnelOptions }) => {
        return {
            ...state,
            funnelOptions,
        };
    },
    [COLUMNS]: (state, { columns }) => {
        return {
            ...state,
            columns,
        };
    },

};

export const initialState = {
    loader: false,
    data: {},
    funnelOptions: [],
    columns: {},
};

export default handleActions(reducers, initialState);
