/* Actions of Industry  */

import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import _ from 'lodash'
import moment from 'moment';

const LOADER_LEAD = 'LOADER_LEAD';
const LOADER_POSIBILITY = 'LOADER_POSIBILITY';
const SET_DATA_LEAD = 'SET_DATA_LEAD';
const SET_DATA_POSSIBILITY = 'SET_DATA_POSSIBILITY';
const SET_TOTAL_LEAD = 'SET_TOTAL_LEAD';
const SET_TOTAL_POSSIBILITY = 'SET_TOTAL_POSSIBILITY';
const SET_META_ANUAL = 'SET_META_ANUAL'
const SET_META_MENSUAL = 'SET_META_MENSUAL'
const SET_VENTA_SEMANAL = 'SET_VENTA_SEMANAL'
const SET_MOSTRAR_CALENDAR = 'SET_MOSTRAR_CALENDAR'
const SET_DATE = 'SET_DATE'
const SET_TEXT = 'SET_TEXT'
const PAGE = 'SET_PAGE';
const PAGE_POSIBILITY = 'PAGE_POSIBILITY';


// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoaderLead = loader_lead => ({
    type: LOADER_LEAD,
    loader_lead,
});

const setLoaderPosibility = loader_posibility => ({
    type: LOADER_POSIBILITY,
    loader_posibility,
});

const setDataLead = data_lead => ({
    type: SET_DATA_LEAD,
    data_lead,
});

const setDataPossibility = data_possibility => ({
    type: SET_DATA_POSSIBILITY,
    data_possibility,
});

const setTotalLead = total_lead => ({
    type: SET_TOTAL_LEAD,
    total_lead,
});

const setTotalPossibility = total_possibility => ({
    type: SET_TOTAL_POSSIBILITY,
    total_possibility,
});

const setMetaAnual = meta_anual => ({
    type: SET_META_ANUAL,
    meta_anual,
});
const setMetaMensual = meta_mensual => ({
    type: SET_META_MENSUAL,
    meta_mensual,
});

const setVentaSemanal = venta_semanal => ({
    type: SET_VENTA_SEMANAL,
    venta_semanal,
});

const setText = text => ({
    type: SET_TEXT,
    text,
});

const setCalendar = mostrar_calendar => ({
    type: SET_MOSTRAR_CALENDAR,
    mostrar_calendar,
});

const setDate = date => ({
    type: SET_DATE,
    date,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setPagePosibility = page_posibility => ({
    type: PAGE_POSIBILITY,
    page_posibility,
});

// Lista todos los ledas al momento de seleccionar un rango de fecha
const listLeads = ( semana, anio ) =>(dispatch)=>{

    const params = {
        semana,
        anio
    }
    dispatch( setLoaderLead( true ) )

    api.get(`reportweek/leadsweek`, params ).then((response)=>{

        const total = response.results.pop()

        if( total.total ){
            dispatch(setTotalLead(total.total.toFixed(2)))
        }
        else{
            dispatch(setTotalLead( 0 ))
        }

        dispatch(setDataLead(response))
        
    }).catch((err)=>{
    }).finally(() => {
        dispatch(setLoaderLead(false));
    });
    
}

// Lista los lead cuando se página
const listPageLeads = page =>(dispatch,getStore)=>{

    let { date } = getStore().reportWeek

    const params = {
        semana: date.semana,
        anio: date.anio, 
        page
    }
    
    dispatch( setLoaderLead( true ) )
    
    api.get(`reportweek/leadsweek`, params ).then((response)=>{
        
        response.results.pop()
        
        dispatch(setDataLead(response))

        dispatch( setPage( page ) )
        
    }).catch((err)=>{
        console.log(err)
    }).finally(() => {
        dispatch( setLoaderLead(false) );
    });
}

// Lista los leads con mayor posiblidad de cierre, cuando se selecciona la fecha
const listLeadsPossibility = ( semana, anio ) =>(dispatch)=>{

    dispatch( setLoaderPosibility(true) )

    api.get('reportweek/leadsweekwon',{ semana, anio }).then((response)=>{

        const total = response.results.pop()

        if( total.total ){
            dispatch(setTotalPossibility(total.total.toFixed(2)))
        }else{
            dispatch(setTotalPossibility( 0 ))
        }

        dispatch(setDataPossibility(response))

    }).catch((err)=>{
    }).finally(() => {
        dispatch( setLoaderPosibility(false));
    });
    
}

// Lista todos los leads con mayor posibilidad cuando se pagina
const listPageLeadsPossibility = ( page ) =>(dispatch, getStore)=>{

    let { date } = getStore().reportWeek

    const params = {
        semana: date.semana,
        anio: date.anio, 
        page
    }

    dispatch( setLoaderPosibility(false));
    api.get('reportweek/leadsweekwon', params).then((response)=>{

        // Extrate el total
        response.results.pop()

        dispatch(setDataPossibility(response))

        dispatch( setPagePosibility( page ) )

    }).catch((err)=>{
        console.log(err)
    }).finally(() => {
        dispatch( setLoaderPosibility(false));
    });
    
}

// Obtiene la meta anual, el procentaje el monto
const metasAnual = (anio) =>(dispatch)=>{

    api.post('reportweek/metaanual',{ anio }).then((response)=>{

        const { anual } = response

        dispatch(setMetaAnual( anual ))

    }).catch((err)=>{
        console.log(err)
    })
    
}

// Obtiene la meta mensual, el procentaje el monto
const metasMensual = (anio, mes) =>(dispatch)=>{

    api.post('reportweek/metamensual',{ anio, mes} ).then((response)=>{

        const { mensual } = response

        dispatch(setMetaMensual( mensual ))

    }).catch((err)=>{
        console.log(err)
    })
    
}

const ventasSemanal = ( semana, anio )=>(dispatch)=>{

    api.post('reportweek/ventasemanal',{ semana, anio }).then((response)=>{

        const { semanal, text } = response

        dispatch( setVentaSemanal( semanal ) )
        dispatch( setText( text ) )

    }).catch((err)=>{
        console.log(err)
    })
}

const handleChange = (value) =>(dispatch)=> {
    dispatch( setText(value) )
}

//  Resgisra una buena noticia
const registrarNoticia = (text)=>(dispatch, getStore)=>{

    let { date } = getStore().reportWeek

    const data = {
        text,
        semana: date.semana,
        anio: date.anio,
    }

    api.post('reportweek/registrarnoticia',data).then( (response)=>{
        NotificationManager.success('Buena noticia registrado correctamente', 'Éxito', 1000);
    }).catch( (err)=>{
        NotificationManager.error('Hubo error en el registro de la buena noticia', 'ERROR', 3000);
    })
}

// Cuando se selecciona la semana se carga todoa la información del reporte
const cargarInformacion = (dateSelected) =>(dispatch, getStore)=>{
    
    let date1 = moment(dateSelected);
    let newDate = {
        anio: date1.year(),
        mes: date1.month()+1,
        semana: date1.isoWeek()
    }
    
    let { date } = getStore().reportWeek

    // En las condiciones verifica si los datos se cambian
    //  En dado caso que sea verdadero hace la petición de los datos al backend
    if( date.anio !== newDate.anio ){

        dispatch( resetVariables() )
        
        date = { anio: 0, mes:0, semana: 0}

        dispatch( metasAnual( newDate.anio ) )
    }


    if( date.mes !== newDate.mes ){
        dispatch( metasMensual( newDate.anio, newDate.mes) )

    }

    if( date.semana !== newDate.semana ){
        dispatch( ventasSemanal( newDate.semana, newDate.anio ) )
        dispatch( listLeads( newDate.semana, newDate.anio ) )
        dispatch( listLeadsPossibility( newDate.semana, newDate.anio ) )
    }

    dispatch( setDate(newDate))
    dispatch(setCalendar(false))
 
} 

// Cuando se cambia de ventana resetea todos los variables del storage
const resetVariables = () =>(dispatch)=>{

    dispatch( setDataLead({  count: 0 }) )
    dispatch( setDataPossibility({ count: 0 }) )
    dispatch( setTotalLead(0) )
    dispatch( setTotalPossibility(0) )
    dispatch( setMetaAnual({}) )
    dispatch( setMetaMensual({}) )
    dispatch( setVentaSemanal(0) )
    dispatch( setDate({ anio: 0, mes:0, semana: 0}))
    dispatch( setText('') )
    dispatch( setCalendar(false) )
    dispatch( setPage(1) )
    dispatch( setPagePosibility(1) )
}


export const actions = {
    cargarInformacion,
    registrarNoticia,
    handleChange,
    resetVariables,
    setCalendar,
    listPageLeads,
    listPageLeadsPossibility
};

export const reducers = {
    [LOADER_LEAD]: (state, { loader_lead }) => {
        return {
            ...state,
            loader_lead,
        };
    },
    [LOADER_POSIBILITY]: (state, { loader_posibility }) => {
        return {
            ...state,
            loader_posibility,
        };
    },
    [SET_DATA_LEAD]: (state, { data_lead }) => {
        return {
            ...state,
            data_lead,
        };
    },
    [SET_DATA_POSSIBILITY]: (state, { data_possibility }) => {
        return {
            ...state,
            data_possibility,
        };
    },
    [SET_TOTAL_LEAD]: (state, { total_lead }) => {
        return {
            ...state,
            total_lead,
        };
    },
    [SET_TOTAL_POSSIBILITY]: (state, { total_possibility }) => {
        return {
            ...state,
            total_possibility,
        };
    },
    [SET_META_ANUAL]: (state, { meta_anual }) => {
        return {
            ...state,
            meta_anual,
        };
    },
    [SET_META_MENSUAL]: (state, { meta_mensual }) => {
        return {
            ...state,
            meta_mensual,
        };
    },
    [SET_VENTA_SEMANAL]: (state, { venta_semanal }) => {
        return {
            ...state,
            venta_semanal,
        };
    },
    [SET_MOSTRAR_CALENDAR]: (state, { mostrar_calendar }) => {
        return {
            ...state,
            mostrar_calendar,
        };
    },
    [SET_TEXT]: (state, { text }) => {
        return {
            ...state,
            text,
        };
    },
    [SET_DATE]: (state, { date }) => {
        return {
            ...state,
            date,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE_POSIBILITY]: (state, { page_posibility }) => {
        return {
            ...state,
            page_posibility,
        };
    },
    
};

export const initialState = {
    loader_lead: false,
    loader_posibility: false,
    data_lead: {
        count: 0
    },
    data_possibility: {
        count: 0
    },
    total_lead: 0,
    total_possibility: 0,
    meta_anual:{},
    meta_mensual:{},
    venta_semanal: 0,
    mostrar_calendar: false,
    date: {
        anio: 0,
        mes: 0,
        semana: 0
    },
    text: '',
    page: 1,
    page_posibility: 1,
};

export default handleActions(reducers, initialState);