/* Actions of Industry  */

import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import _ from 'lodash';
import moment from 'moment';
import { actions as GestionLeads } from './GestionLeads';


const SUBMIT = 'CONTACT_SUBMIT';
const LOADER = 'CONTACT_LOADER';
const SET_DATA = 'SET_DATA';
const CHANGE_TYPE = 'CHANGE_TYPE';
const CONVERT_COIN = 'CONVERT_COIN';
const SET_AMOUNT = 'SET_AMOUNT';
const SET_EMBUDO = 'SET_EMBUDO';
const SET_VER = 'SET_VER';
const SET_IDLEAD = 'SET_IDLEAD';


export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setAmount = amount => ({
    type: SET_AMOUNT,
    amount,
});

const setConvertCoin = quetzals => ({
    type: CONVERT_COIN,
    quetzals,
});

const setChange = changeType => ({
    type: CHANGE_TYPE,
    changeType,
});

export const setEmbudo = embudo => ({
    type: SET_EMBUDO,
    embudo,
});

const setVer = ver => ({
    type: SET_VER,
    ver,
});

const setIdLead = idLead => ({
    type: SET_IDLEAD,
    idLead,
});

export const resetProperties = ()=>(dispatch)=>{
    dispatch( setVer(false) )
    dispatch( setIdLead(0) )
    dispatch( setChange(0) )
    dispatch( setConvertCoin(0) )
    dispatch( setAmount(0) )
}
// ------------------------------------
// Actions
// ------------------------------------

const onSubmit = () => (dispatch, getStore) => {
    const { values } = getStore().form.leadForm;
    const { quetzals, embudo, idLead, ver } = getStore().lead;
    const date = moment(values.closing_date).format("YYYY-MM-DD");

    const data = {
        ...values,
        closing_date: date,
        quetzal: quetzals,
        company: values.company.value,
        contact: values.contact.value,
        owner_lead: values.owner_lead.value,
        sales_channel: values.sales_channel.value,
        coin_lead: values.coin_lead.value,
        sales_funnel: embudo.id,
        business_stage: values.business_stage.value,
        possibility_won: values.possibility_won,
    };

    if(idLead){

        api.put(`leads/${idLead}`, data).then((response) => {
            NotificationManager.success('El Lead se actualizó correctamente', 'Éxito', 1000);

            const { closeModal, detalle } = GestionLeads;
        
            // Refresh colums 
            dispatch(detalle(embudo.id));
        
            // Close modal
            dispatch(closeModal());
            
        }).catch(() => {
            console.log('Error')
            NotificationManager.error('Hubo error en la actualización', 'ERROR', 0);
        });

    }else{
        
        api.post('leads', data).then((response) => {
            NotificationManager.success('Lead registrado correctamente', 'Éxito', 1000);
            const { closeModal, detalle } = GestionLeads;
        
            // Refresh colums 
            dispatch(detalle(embudo.id));
        
            // Close modal
            dispatch(closeModal());
        }).catch((err) => {
            console.log(err);
            NotificationManager.error('Hubo error en el registro del lead', 'ERROR', 3000);
        });
    }
};


// Cambia el tipo de moneda cuando se cambia el select field
const changeTypeCoin = data => (dispatch, getStore) => {

    const { change_type } = data;
    dispatch(setChange(change_type));

    const { amount } = getStore().lead;

    const quetales = amount * change_type;
    dispatch(setConvertCoin(quetales.toFixed(2)));
};

// Convierte el cambio cuando se escribe algo en el input del monto
const convertCoin = data => (dispatch, getStore) => {
    const { value } = data.target;
    const amount = Number(value) || 0;

    dispatch(setAmount(amount));

    // Convert coin
    const { changeType } = getStore().lead;

    const quetales = amount * changeType;
    dispatch(setConvertCoin(quetales.toFixed(2)));
};

// Obtiene un lead
export const detalle = (id, ver) => (dispatch) => {

    dispatch(setVer(ver))

    api.get(`leads/${id}`).then((response) => {

        dispatch( setConvertCoin( response.quetzal ) )
        dispatch( setAmount( response.amount ) )
        dispatch( setChange( response.coin_lead.change_type ) )
        dispatch( setIdLead(id) )

        dispatch(initializeForm('leadForm', response));

    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    })
};

// Elimina un lead
export const eliminar = (id) => (dispatch, getStore) => {

    const { embudo } = getStore().lead;

    api.eliminar(`leads/${id}`).then((response) => {
        
        const { detalle } = GestionLeads;
        dispatch(detalle(embudo.id));

    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    })
};

// ===========================
//      Lista de datos
// ===========================
const getChangeCoin = () => (dispatch) => {
    let typecoins = [];

    return api.get('config/change-coin').then((response) => {
        typecoins = response.results.map(coin => ({
            value: coin.id,
            label: coin.coin_type,
            change_type: coin.change_type,
        }));
        return typecoins;
    })
        .catch((err) => {
            return typecoins;
        });
};

const getUsuarios = () => (dispatch) => {
    let users = [];

    return api.get('user').then((response) => {
        users = response.results.map(user => ({ value: user.id, label: user.username }));
        return users;
    })
        .catch((err) => {
            return users;
        });
};

const getContacts = () => (dispatch) => {
    let contacts = [];

    return api.get('contacts').then((response) => {
        contacts = response.results.map(contact => ({ value: contact.id, label: contact.name }));
        return contacts;
    })
        .catch((err) => {
            return contacts;
        });
};

const getCompanies = () => (dispatch) => {
    let companies = [];

    return api.get('company').then((response) => {
        companies = response.results.map(company => ({ value: company.id, label: company.name }));
        return companies;
    })
        .catch((err) => {
            return companies;
        });
};

const getChannel = () => (dispatch) => {
    let channels = [];

    return api.get('sales_channel').then((response) => {
        channels = response.results.map(channel => ({ value: channel.id, label: channel.name }));
        return channels;
    })
        .catch((err) => {
            return channels;
        });
};

const getEmbudo = () => (dispatch, getStore) => {
    const { embudo } = getStore().lead;
    console.log(embudo);

    let stages = [];
    if (embudo.id) {
        return api.get(`sales-funnel/${embudo.id}`).then((response) => {

            stages = response.stages_sales.map(stage => ({ value: stage.id, label: stage.name, percentage: stage.percentage }));
            return stages;
        }).catch((err) => {
            return stages;
        });
    }
    return stages;
};

//-----------------Tasks-------------------------------

const taskAdd = () =>(dispatch, getStore) =>{
    const { values } = getStore().form.taskForm;
    const { idLead } = getStore().lead;

    console.log(values)
    console.log(idLead)

}

export const actions = {
    onSubmit,
    getChangeCoin,
    getUsuarios,
    getCompanies,
    getContacts,
    getChannel,
    changeTypeCoin,
    convertCoin,
    getEmbudo,
    taskAdd,
};

export const reducers = {
    [CHANGE_TYPE]: (state, { changeType }) => {
        return {
            ...state,
            changeType,
        };
    },
    [CONVERT_COIN]: (state, { quetzals }) => {
        return {
            ...state,
            quetzals,
        };
    },
    [SET_AMOUNT]: (state, { amount }) => {
        return {
            ...state,
            amount,
        };
    },
    [SET_EMBUDO]: (state, { embudo }) => {
        return {
            ...state,
            embudo,
        };
    },
    [SET_VER]: (state, { ver }) => {
        return {
            ...state,
            ver,
        };
    },
    [SET_IDLEAD]: (state, { idLead }) => {
        return {
            ...state,
            idLead,
        };
    },

};

export const initialState = {
    changeType: 0,
    amount: 0,
    quetzals: 0.00,
    embudo: {},
    ver: false,
    idLead: 0
};

export default handleActions(reducers, initialState);
