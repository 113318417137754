import { connect } from 'react-redux'
import { actions } from  '../../../redux/modules/salesFunnel/salesFunnel'

import SalesFunnel from './ListSalesFunnel'
import SaleFunnel from './SaleFunnel'

const ms2p = (state) => {
  return {
    ...state.salesFunnel,
  };
};

const md2p = { ...actions };

//==================
// Conection List Sales Funnels
//==================
  const ListSalesFunnel = connect(ms2p, md2p)(SalesFunnel);
  
//==========================
// Conection Create Sales Funnel
//==========================
const CreateSaleFunnel = connect(ms2p, md2p)(SaleFunnel);


  export const connectionFunnel = {
    ListSalesFunnel,
    CreateSaleFunnel
  }