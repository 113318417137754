/* eslint-disable no-param-reassign */
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { api } from 'api';
import { NotificationManager } from 'react-notifications';
import { handleActions } from 'redux-actions';

const LOADER = 'ANNUAL_LOADER';
const DATA = 'ANNUAL_DATA';
const ITEM = 'ANNUAL_ITEM';
const PAGE = 'ANNUAL_PAGE';
const ORDERING = 'ANNUAL_ORDERING';
const SEARCH = 'ANNUAL_SEARCH';
const STEP = 'ANNUAL_STEP';
const BUSINESS_LINES = 'BUSINESS_LINES';

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setItem = item => ({
    type: ITEM,
    item,
});
const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});
const setSearch = search => ({
    type: SEARCH,
    search,
});
const setStep = step => ({
    type: STEP,
    step,
});
const setBusinessLines = businessLines => ({
    type: BUSINESS_LINES,
    businessLines,
});

// -----------------------------------
// Actions
// -----------------------------------
const nextPage = () => (dispatch, getState) => {
    const goalsValues = getState().form.annualGoalsForm.values;
    const totalValues = parseFloat(goalsValues.goal_first_trimester)
        + parseFloat(goalsValues.goal_second_trimester)
        + parseFloat(goalsValues.goal_third_trimester)
        + parseFloat(goalsValues.goal_fourth_trimester);
    if (totalValues !== 100) {
        NotificationManager.error('El porcentaje total debe ser 100%', 'Error', 3000);
    } else {
        const stateStep = getState().annualGoals.step;
        dispatch(setStep(stateStep + 1));
    }
};

const previousPage = () => (dispatch, getState) => {
    const stateStep = getState().annualGoals.step;
    dispatch(setStep(stateStep - 1));
};
const onChangeFieldArray = input => (dispatch, getState) => {
    const {values} = getState().form.annualGoalsForm;
    const form = getState().form.annualGoalsForm.values;

    const { value, name } = input;
    const linea = String(name).replace('].percentage', '').replace('lines[', '');
    if (value > 0) {
        const newValue = (parseFloat(value) * parseFloat(form.goal_quetzales))
        / 100;
        values.lines[parseInt(linea, 10)].percentage_quetzal = newValue;
    } else {
        values.lines[parseInt(linea, 10)].percentage_quetzal = 0;
    }
};
const onChangeField = input => (dispatch, getState) => {
    const { value, name } = input;
    const form = getState().form.annualGoalsForm.values;
    const nameInput = `${name}_quetzal`;
    if (value > 0) {
        const newValue = (parseFloat(value) * parseFloat(form.goal_quetzales))
            / 100;
        const newForm = Object.assign(form, {
            [nameInput]: newValue,
        });
        dispatch(initializeForm('annualGoalsForm', newForm));
    } else {
        const newForm = Object.assign(form, { [nameInput]: 0 });
        dispatch(initializeForm('annualGoalsForm', newForm));
    }
};


const getBusinessLines = () => (dispatch, getState) => {
    const businessLines = [];
    return api.get('business-lines').then(
        (response) => {
            response.results.forEach((element) => {
                delete element.created;
                delete element.description;
                delete element.modified;
                delete element.is_active;
                businessLines.push(element);
            });
            dispatch(setStep(0));
            dispatch(setBusinessLines(businessLines));
            dispatch(initializeForm('annualGoalsForm', {lines: [...businessLines]}));
        }

    ).catch(
        () => []
    ).finally();
};
const crear = () => (dispatch, getState) => {
    const {values} = getState().form.annualGoalsForm;
    if (!values.is_active_goal) {
        values.is_active_goal = false;
    }
    const suma = (previous, item) => previous + item.percentage;
    const total = values.lines.reduce(suma, 0);
    if (total !== 100) {
        NotificationManager.error('El porcentaje total debe ser 100%', 'Error', 3000);
    } else {
        api.post('annual-goal', values).then(() => {
            dispatch(setStep(0));
            dispatch(push('/annual-goals'));
            NotificationManager.success('Registro creado', 'Éxito', 3000);
        }).catch(() => {
            NotificationManager.error('Error en guardado', 'Error', 3000);
        });
    }
};

const detalle = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`annual-goal/${id}`).then((response) => {
        dispatch(initializeForm('annualGoalsForm', response));
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listar = (page = 1) => (dispatch, getState) => {
    const resource = getState().annualGoals;
    const params = {page};
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('annual-goal', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const actualizar = () => (dispatch, getState) => {
    const {values} = getState().form.annualGoalsForm;
    const suma = (previous, item) => previous + item.percentage;
    const total = values.lines.reduce(suma, 0);
    if (total !== 100) {
        NotificationManager.error('El porcentaje total debe ser 100%', 'Error', 3000);
    } else {
        api.put(`annual-goal/${values.id}`, values).then(() => {
            dispatch(setStep(0));
            dispatch(push('/annual-goals'));
            NotificationManager.success('La Meta se actualizó correctamente', 'Éxito', 3000);
        }).catch(() => {
            NotificationManager.error('Hubo error en la actualización', 'ERROR', 1000);
        });
    }
};
const eliminar = id => (dispatch) => {
    api.eliminar(`annual-goal/${id}`).then(() => {
        NotificationManager.success('Meta eliminado correctamente', 'Éxito', 3000);
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error('Hubo error en la eliminación', 'ERROR', 3000);
    });
};
const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().annualGoals.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    nextPage,
    previousPage,
    onChangeField,
    getBusinessLines,
    onChangeFieldArray,
    crear,
    listar,
    detalle,
    actualizar,
    eliminar,
    onSortChange,
    searchChange,
};


// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [STEP]: (state, { step }) => {
        return {
            ...state,
            step,
        };
    },
    [BUSINESS_LINES]: (state, { businessLines}) => {
        return {
            ...state,
            businessLines,
        };
    },
};

const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    step: 0,
    stepsTitle: [
        { title: 'Info. General' },
        { title: 'Lineas de Negocios' },
    ],
    businessLines: [],
};

export default handleActions(reducers, initialState);
