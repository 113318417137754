import React, { Component } from 'react';
import Grid from "../Utils/Grid";
import Sidebar from 'react-sidebar';
import FilterForm from './filter';
import Select from './from';
import currency from 'currency-formatter'

const stylesSidebar = {
    root: {
        top: 60,
        display: 'flex',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        boxSizing: 'box-sizing',
    },
    sidebar: {
        zIndex: 2,
        transition: 'transform .3s ease-out',
        WebkitTransition: '-webkit-transform .3s ease-out',
        willChange: 'transform',
        overflowY: 'auto',
        backgroundColor: 'white',
    },
    content: {
        margin: 0,
        padding: '24px',
        paddingTop: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
        transition: 'left .3s ease-out, right .3s ease-out',
        boxSizing: 'box-sizing',
    },
};

// Interfaz para mostrar los leads y sus estadisticas
export default class Leads extends Component {
    // Obtiene los embudos existentes
    componentWillMount = () => {
        const { getSalesFunnel } = this.props;
        getSalesFunnel()
    };
    // Al salir del componente reseteamos valores y cerramos el Sidebar
    componentWillUnmount = () => {
        const { closeSidebar, resetValues } = this.props;
        closeSidebar()
        resetValues()
    }

    render() {
        const { data, loader, page, listLeads, listPageLeads, funnelOptions, loadingFunnel, stats, quantity } = this.props;
        const { closeSidebar, stateSidebar, openSidebar } = this.props;
        const { getUsers, getChannels, getStages, embudo } = this.props;
        return (
            <React.Fragment>
                <div className="uk-flex">
                    <Sidebar
                        sidebar={(
                            <FilterForm
                                closeSidebar={closeSidebar}
                                getUsers={getUsers}
                                getChannels={getChannels}
                                getStages={getStages}
                                listLeads={listLeads}
                                embudo={embudo}
                            />
                        )}
                        open={stateSidebar}
                        pullRight
                        styles={stylesSidebar}
                    >
                        <br />
                        <h3 className="uk-text-bold uk-text-lead">Leads</h3>
                        <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">
                            <div className="uk-flex uk-flex-between uk-padding uk-padding-remove-bottom uk-margin-auto-top@s">

                                <Select
                                    funnelOptions={funnelOptions}
                                    loadingFunnel={loadingFunnel}
                                />
                                <button
                                    className="uk-button uk-button-primary uk-border-rounded uk-margin-small-bottom  uk-button-small uk-flex"
                                    type="button"
                                    onClick={() => {
                                        openSidebar();
                                    }}
                                >
                                    <i
                                        style={{ marginLeft: '2px' }}
                                        className="material-icons"
                                    >
                                        filter_list
                                    </i>
                                </button>

                            </div>

                            <div className="uk-container uk-flex uk-flex-center uk-padding-small uk-text-center">
                                <div className="uk-card uk-card-default uk-card-body uk-width-1-4@m">
                                    <h6> Leads: {data.count} </h6>
                                </div>
                                <div className="uk-card uk-card-default uk-card-body uk-width-1-4@m uk-margin-left">
                                    <h6> {currency.format( stats.monto_total, { code: 'GTQ'}) } </h6>
                                </div>
                            </div>


                            <ul className="uk-grid uk-grid-small uk-text-center uk-flex-center" uk-grid>
                                    {quantity.etapas &&
                                        quantity.etapas.map((items, key) => {
                                            return  <li >
                                                        <div className="uk-card uk-card-default uk-card-body">
                                                            <h5>{items.name}</h5>
                                                            <p>{items.total}</p>
                                                        </div>
                                                    </li>
                                        })

                                    }
                            </ul>

                            <div className="uk-grid uk-grid-small uk-text-center" uk-grid>

                                <div className="uk-width-1-4@m">
                                    <div className="uk-card uk-card-small uk-card-default uk-card-body">
                                        <h5 className="uk-card-header">Cantidad Leads Ganados</h5>
                                        <p className="uk-card-title">{stats.percentage_count} %</p>

                                    </div>
                                </div>
                                <div className="uk-width-1-4@m">
                                    <div className="uk-card uk-card-small uk-card-default uk-card-body">
                                        <h5 className="uk-card-header">Monto Leads Ganados</h5>
                                        <p className="uk-card-title">{stats.percentage_money} %</p>
                                    </div>
                                </div>
                                <div className="uk-width-1-4@m">
                                    <div className="uk-card uk-card-small uk-card-default uk-card-body">
                                        <h5 className="uk-card-header">Promedio Ganados</h5>
                                        <p className="uk-card-title">{stats.won_days_average} dias</p>
                                    </div>
                                </div>
                                <div className="uk-width-1-4@m">
                                    <div className="uk-card uk-card-small uk-card-default uk-card-body">
                                        <h5 className="uk-card-header">Promedio Perdidos</h5>
                                        <p className="uk-card-title">{stats.lost_days_average} dias</p>
                                    </div>
                                </div>

                            </div>

                            <div className="uk-card uk-padding">
                                <Grid
                                    data={data}
                                    loading={loader}
                                    onPageChange={listPageLeads}
                                    //onSortChange={onSortChange}
                                    page={page}
                                    striped
                                >
                                    <TableHeaderColumn
                                        isKey
                                        dataField="name"
                                        dataSort
                                        dataAlign="center"
                                        width="10%"
                                    >
                                        Nombre
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="sales_channel"
                                        dataSort
                                        dataAlign="center"
                                        width="15%"
                                        dataFormat={(cell) => {
                                            return cell.label;
                                        }}
                                    >
                                        Canal de Venta
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="sales_channel"
                                        dataSort
                                        dataAlign="center"
                                        width="11%"
                                        dataFormat={(cell) => {
                                            return cell.percentage;
                                        }}
                                    >
                                        Comision (%)
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="business_stage"
                                        dataSort
                                        dataAlign="center"
                                        width="10%"
                                        dataFormat={(cell) => {
                                            return cell.label;
                                        }}
                                    >
                                        Estado
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="quetzal"
                                        dataSort
                                        headerAlign="center"
                                        dataAlign="right"
                                        width="15%"
                                        dataFormat={
                                            (cell)=> currency.format( cell, { code: 'GTQ'} )
                                        }
                                    >
                                        Monto
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cycle_days"
                                        dataSort
                                        dataAlign="center"
                                        width="15%"
                                    >
                                        Ciclo de Vida (Dias)
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </Sidebar>
                </div>
            </React.Fragment >
        );
    }
}
