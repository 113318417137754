import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Item from './Item';

const getItemStyle = isDragging => ({
    background: isDragging ? "#eaf0f6" : "white",
});


const Column = (props) => {
    const {leads, column, detalleLead, openModal, eliminarLead } = props;
    return (
        <div className="container__column">
            <div className="title">
                <h4>{column.name}</h4>
                <progress value={column.percentage} max="100" />
                <p>
                    Leads:
                    {' '}
                    {column.count}
                    {' '}
                </p>
            </div>
            <Droppable droppableId={`${column.id}`}>
                {(provided, snapshot) => (
                    <div
                        className="column__body"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getItemStyle(
                            snapshot.isDraggingOver
                        )}
                    >
                        {
                            leads.map((lead, index) => {
                                if (lead !== undefined) {
                                    return (
                                        <div key={lead.id}>
                                            <Item 
                                                lead={ lead } 
                                                index={ index }
                                                detalleLead={ detalleLead } 
                                                eliminarLead = { eliminarLead }
                                                openModal={ openModal }
                                            />
                                        </div>
                                    );
                                }
                                return null;
                            })
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>

    );
};
export default Column;
