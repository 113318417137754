import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Papa from "papaparse";
import { Link } from 'react-router-dom';
import {renderCurrency, AsyncSelectField} from '../../Utils/renderField/renderField';
import type_coins from "../../../../../assets/csv/Monedas.csv";

const validate = (values) => {
    const errors = {};
    if (!values.coin_type) {
        errors.coin_type = 'Campo requerido';
    }

    if (!values.change_type) {
        errors.change_type = 'Campo requerido';
    }
    return errors;
};

const ChangeForm = (props) => {
    const { handleSubmit, actualizar, ver } = props;
    const options = async (search = null) => {
        const data = [];
        await Papa.parse(type_coins, {
            download: true,
            complete: ((results) => {
                if (search != null) {
                    results.data.forEach((x) => {
                        if (x.Moneda.toLowerCase().includes(search.toLowerCase())) {
                            data.push({value: x.Codigo, label: x.Moneda});
                        }
                    });
                } else {
                    results.data.forEach((x) => {
                        data.push({value: x.Codigo, label: x.Moneda});
                    });
                }
            }),
            header: true,
        });
        return data;
    };
    return (
        <form onSubmit={handleSubmit} className="uk-card uk-card-default uk-padding uk-margin-auto uk-flex-1 uk-flex-center">
            <div className="uk-width-1-3@m uk-margin-auto uk-margin-bottom">
                <label htmlFor="coin_type">Selecione Moneda</label>
                {/* eslint-disable-next-line max-len */}
                <Field
                    name="coin_type"
                    label="Tipos de Monedas"
                    placeholder="Buscar..."
                    component={AsyncSelectField}
                    loadOptions={options}
                    disabled={ver}
                />
            </div>
            <div className="uk-width-1-3@m uk-margin-auto">
                <label htmlFor="change_type">Tipo de Cambio</label>
                <Field
                    name="change_type"
                    label="Tipo de Cambio"
                    className="uk-input uk-border-rounded"
                    component={renderCurrency}
                    disabled={ver}
                />
            </div>
            <br />
            <br />
            <div className="uk-width-1-3@m uk-margin-auto uk-flex uk-flex-center">
                <Link
                    className="uk-button uk-button-secondary uk-border-rounded uk-button-small uk-flex"
                    to="/config/change-coin"
                >
                    Cancelar
                    <i style={{marginLeft: "2px"}} className="material-icons">cancel</i>
                </Link>
                {!ver
            && (
                <button
                    type="submit"
                    className="uk-button uk-button-primary uk-border-rounded uk-button-small uk-margin-left uk-flex"
                >
                    {actualizar ? 'Actualizar' : 'Registrar'}
                    <i style={{marginLeft: "2px"}} className="material-icons">save</i>
                </button>

            )
                }
            </div>
        </form>
    );
};

export default reduxForm(
    {
        form: 'ChangeForm',
        validate,

    }
)(ChangeForm);
