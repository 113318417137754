import React from 'react'
import Grid from '../../Utils/Grid';
import currency from 'currency-formatter'

export const GridLeads = (props) => {

    const { data_lead, data_possibility,total_lead, listPageLeads, page_posibility,
        listPageLeadsPossibility, total_possibility, page, loader_lead} = props
    
    return (
        <React.Fragment>
            <div className="uk-flex-row">

            
                <div className="uk-child-width-1-2@s uk-grid uk-margin-medium-top ">
                    
                    <div className="uk-margin-large-bottom">

                        <h5 className="uk-padding-small">Leads de la semana</h5>
                        
                        <Grid
                        data={data_lead}
                        loading={ loader_lead }
                        onPageChange={listPageLeads}
                        page={page}
                        >

                            <TableHeaderColumn
                                isKey
                                dataField="name"
                                headerAlign='center'
                                dataSort
                            >
                                Nombre Lead
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="business_stage"
                                headerAlign='center'
                                dataFormat={ cell => {
                                    if( cell === 'Ganado' ){
                                        return <span style={{ color: '#06d6a0', fontWeight:'bold'}}>{ cell }</span>
                                    }else if( cell === 'Perdido' ){
                                        return <span style={{ color: '#FA7D69', fontWeight:'bold'}}>{ cell }</span>
                                    }else{
                                        return <span>{ cell }</span>
                                    }
                                }}

                                dataSort
                            >
                                Estado
                            </TableHeaderColumn> 

                            <TableHeaderColumn
                                dataField="quetzal"
                                headerAlign='center'
                                dataAlign='right'
                                dataFormat = { (cell)=> currency.format( cell, { code: 'GTQ'} ) }
                                dataSort
                            >
                                Monto
                            </TableHeaderColumn>        

                        </Grid>
                    
                        <h4 className="">Total: <span>{ currency.format( total_lead, { code: 'GTQ'} ) }</span></h4>
                        
                    </div>

                    <div className="uk-margin-large-bottom" >
                        <h5 className="uk-padding-small">Leads con mayor posibilidad</h5>
                        {/* <div className="uk-margin-medium-bottom"> */}

                        
                            <Grid
                            data={data_possibility}
                            // loading={loader}
                            onPageChange={listPageLeadsPossibility}
                            // onSortChange={onSortChange}
                            page={ page_posibility }
                            >

                                <TableHeaderColumn
                                    isKey
                                    dataField="name"
                                    headerAlign='center'
                                    dataSort
                                >
                                    Nombre Lead
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="business_stage"
                                    headerAlign='center'
                                    dataSort
                                >
                                    Estado
                                </TableHeaderColumn> 

                                <TableHeaderColumn
                                    dataField="quetzal"
                                    headerAlign='center'
                                    dataAlign='right'
                                    dataFormat = { (cell)=> currency.format( cell, { code: 'GTQ'} ) }
                                    dataSort
                                >
                                    Monto
                                </TableHeaderColumn>        

                            </Grid>
                        
                        {/* </div> */}

                        <h4>Total: <span>{ currency.format ( total_possibility, { code: 'GTQ'} ) }</span></h4>
                    </div>

                </div>
            </div>
            
        </React.Fragment>
    )
}
