import { connect } from 'react-redux'
import { actions } from  '../../../redux/modules/company/company'

import Companies from './ListCompany'
import Company from './Company'

const ms2p = (state) => {
  return {
    ...state.company,
  };
};

const md2p = { ...actions };

//==================
// Conection List Company
//==================
  const ListCompanies = connect(ms2p, md2p)(Companies);
  
//==========================
// Conection Create Company
//==========================
const CreateCompany = connect(ms2p, md2p)(Company);


  export const connectionCompany = {
    ListCompanies,
    CreateCompany
  }