import React, {Component} from 'react';
import Formulario from './Formulario';


class CrearSalesChannel extends Component{
    componentWillMount = () => {
        const {match, leer} = this.props;
        if(match.params.id){
            const id = match.params.id;
            leer(id);
        }        
    }

    actualizarFormulario = (data) => {
        const { editar } = this.props;        
        editar(data.id, data);
    }
    render(){
        console.log("props: ", this.props);
        const {match, crear,location} = this.props;
        const funcionEnvio = match.params.id ? this.actualizarFormulario : crear;

        return(
            <div >
                <br/>
                 <h2 className="uk-margin-auto uk-text-bold uk-text-lead">
                    Canal de venta
                </h2>               
                <Formulario 
                    onSubmit={funcionEnvio}
                    actualizar={match.params.id ? true : false}
                    ver={location.pathname.includes('ver')}
                />
            </div>            
        )
    }
}

export default CrearSalesChannel;