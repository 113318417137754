import React, {Component} from 'react';
import {CanvasJS, CanvasJSChart} from 'canvasjs-react-charts';


class SalesFunner extends Component {

    render() {
        const {
             columns, data
        }=this.props;
        var dataPoint;
        var total;
		const estados=[]
		for (var [key, value] of Object.entries(columns)) {
			estados.push({
				y: 5,
				label: value.name,
				count:value.count,
				monto:0
			})
			
		}
		const options = {
		
			title:{
				text: "Embudo de ventas"
			},
			
			data: [{
				type: "funnel",
				toolTipContent: "<b>Leads</b>: {count}% <b>Monto</b>: Q.{monto}",
				
				indexLabel: " {label} {count}%",
				dataPoints:estados
			}]
		}
		//calculate percentage
		dataPoint = options.data[0].dataPoints;
		total = data.length;
	
		for(var i = 0; i < dataPoint.length; i++) {
				options.data[0].dataPoints[i].count = ((dataPoint[i].count / total) * 100).toFixed(0);
				for( var j = 0; j< data.length;j++)
				{
						if(options.data[0].dataPoints[i].label == data[j].estado){
							options.data[0].dataPoints[i].monto = (dataPoint[i].monto + data[j].monto) ;
						}		
				}
		}
		 
        return (
            <div>
            {
				estados.length>0 &&				 
                <CanvasJSChart options = {options}
				/>
			}
        
            </div>
        );
    }
}

export default SalesFunner;
