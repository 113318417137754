import React, { Component } from 'react';
import {Link, NavLink} from "react-router-dom";

class SideBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { toggleOpen, navToggle, logOut } = this.props;
        return (
            <aside className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 ${toggleOpen ? '' : 'open'}`}>
                <div className="main-navbar">
                    <nav
                        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0 navbar navbar-light"
                    >
                        <a href="#" className="w-100 mr-0 navbar-brand">
                            <div className="d-table m-auto">
                                <img
                                    id="main-logo"
                                    className="d-inline-block align-top mr-1"
                                    src={require('assets/img/logo.png')}
                                    alt="Logo"
                                />
                            </div>
                        </a>
                        <a
                            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={navToggle}
                        >
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper">
                    <ul className="nav--no-borders flex-column nav">
                        <li className="nav-item">
                            <NavLink to="/dashboard" exact className="nav-link " activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">edit</i>
                                </div>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <Link to="/gestion-leads" className="nav-link">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Gestión Leads</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/sales_channel" className="nav-link" activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Canales de ventas</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/business-lines" className="nav-link" activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Lineas de Negocios</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <Link to="/sales-funnel" className="nav-link" activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Embudo de Ventas</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/company" className="nav-link" activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Companias</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/industry" className="nav-link" activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Industrias</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contacts" className="nav-link" activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Contactos</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/config/change-coin" className="nav-link" activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Tipos de Cambio</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/annual-goals" className="nav-link" activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Metas Anuales</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/users" className="nav-link" activeClassName="active">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Usuarios</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/reportes/leads" className="nav-link">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Reportes</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/reportes/semanal" className="nav-link">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">vertical_split</i>
                                </div>
                                <span>Reportes Semanal</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/login" onClick={logOut} className="nav-link">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">lock</i>
                                </div>
                                <span>Log Out</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }
}

export default SideBar;
