import React, {Component} from 'react';
import SalesFunner  from './SalesFunner';
import Select from './from';

class DashboardList extends Component {
    componentWillMount = () => {
        const { getSalesFunnel } = this.props;
        getSalesFunnel();
    };
    render() {
        const {
            funnelOptions, detalle, columns, data
        }=this.props;
        return (
            <div>
        
                <br />
                <h3 className="uk-text-bold uk-text-lead uk-width-1-2">
                    Dashboard
                </h3>
                
                <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">
                <div className="uk-flex uk-flex-between uk-padding uk-padding-remove-bottom uk-margin-auto-top@s">

                <Select
                    funnelOptions={funnelOptions}
                    detalle={detalle}
                   
                />
                 
              
                </div>
                <div className="uk-width-1-2">
                <SalesFunner
                columns={columns}
                data={data}
                />
                </div>
                </div>
            </div>
        );
    }
}

export default DashboardList;
