import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderNumber } from '../Utils/renderField';


const BusinessLinesForm = (props) => {
    const { handleSubmit, actualizarLinea, ver, isNested, closeModal } = props;

    return (
        <form
            onSubmit={handleSubmit}
            className="uk-card uk-card-default uk-padding uk-margin-auto uk-flex-1 uk-flex-center"
        >
            <div className="uk-width-1-2@m uk-margin-auto uk-margin-bottom">
                <label>Nombre</label>
                <Field
                    className="uk-input uk-border-rounded"
                    component={renderField}
                    name="name"
                    disabled={ver}
                />
            </div>
            <div className="uk-width-1-2@m uk-margin-auto">

                <label>Descripción</label>
                <Field
                    className="uk-input uk-border-rounded"
                    component={renderField}
                    name="description"
                    disabled={ver}
                />
            </div>
            <br />
            <div className="uk-width-1-2@m uk-margin-auto">
                <div className="uk-flex uk-flex-center">
                    {isNested
                        ? (
                            <button
                                type="button"
                                className="uk-button uk-button-secondary uk-button-small uk-border-rounded uk-flex"
                                onClick={() => closeModal()}
                            >
                                Cancelar
                                <i style={{marginLeft: "2px"}} className="material-icons">cancel</i>
                            </button>
                        )
                        : (
                            <a
                                className="uk-button uk-button-secondary uk-button-small uk-border-rounded uk-flex"
                                href="/#/business-lines"
                            >
                                Cancelar
                                <i style={{marginLeft: "2px"}} className="material-icons">cancel</i>
                            </a>
                        )
                    }
                    {!ver
                && (
                    <button
                        type={isNested ? "button" : "submit"}
                        onClick={isNested ? handleSubmit : null}
                        className="uk-button uk-button-primary uk-button-small uk-border-rounded uk-margin-small-left uk-flex"
                    >
                        {actualizarLinea ? 'Actualizar' : 'Registrar'}
                        {<i style={{marginLeft: "2px"}} className="material-icons">save</i>}
                    </button>
                )
                    }
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'businessLines', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            /* name: validators.exists()('Este campo es requerido'),
            description: validators.exists()('Este campo es requerido'), */
        });
    },
})(BusinessLinesForm);
