import React, { Component } from 'react';
import Stepper from 'react-stepper-horizontal';
import FormStepOne from './FormStepOne';
import FormStepTwo from './FormStepTwo';

class Form extends Component {
    componentWillMount = () => {
        const { getBusinessLines, match, detalle } = this.props;
        if (match.params.id) {
            const {id} = match.params;
            detalle(id);
        } else {
            getBusinessLines();
        }
    };

    render() {
        const {
            step,
            nextPage,
            stepsTitle,
            previousPage,
            onChangeField,
            onChangeFieldArray,
            businessLines,
            location,
            crear,
            actualizar,
            match,
        } = this.props;
        const onSubmit = match.params.id ? actualizar : crear;
        return (
            <div>
                <Stepper steps={stepsTitle} activeStep={step} />
                <br />
                <h2 className="uk-margin-auto uk-text-bold uk-text-lead">
                    Meta Anual
                </h2>
                {step === 0 && (
                    <FormStepOne
                        onChangeField={onChangeField}
                        onSubmit={nextPage}
                        ver={location.pathname.includes('ver')}
                    />
                )}
                {step === 1 && (
                    <FormStepTwo
                        businessLines={businessLines}
                        previousPage={previousPage}
                        onSubmit={onSubmit}
                        actualizar={!!match.params.id}
                        onChangeFieldArray={onChangeFieldArray}
                        ver={location.pathname.includes('ver')}

                    />
                )}
            </div>
        );
    }
}

export default Form;
