import React, { Component } from 'react';
import LeadModal from '../leads/LeadModal';
import Grid from '../Utils/Grid';
import {standardActions} from '../Utils/Grid/StandardActions';

export default class ListContacts extends Component {

    componentWillMount = () => {
        const { listar } = this.props;

        listar();
    }

    // Menja el input de busqueda
    handleSearch = (e) => {
        const { listar, filterContact } = this.props;

        if (e.target.value != '') {
            filterContact(e.target.value);
        } else {
            listar();
        }
    }

    render() {
        const {data, loader, onSortChange, eliminar, listar, page } = this.props;

        return (
            <React.Fragment>
                <br />
                <h3 className="uk-text-lead uk-text-bold">Lista de Contactos </h3>

                <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">

                    <div className="uk-flex uk-flex-between uk-padding uk-padding-remove-bottom uk-margin-auto-top@s">
                        <input
                            className="uk-input uk-border-rounded uk-width-1-5"
                            placeholder="Buscar ..."
                            onChange={this.handleSearch}
                        />
                        <a
                            className="uk-button uk-button-primary uk-border-rounded uk-margin-small-bottom uk-button-small uk-flex"
                            href="/#/contacts/create"
                        >
                            <i style={{marginRight: "4px"}} className="material-icons">add_circle_outline</i>
                            Agregar
                        </a>
                    </div>

                    <Grid
                        className="uk-padding"
                        data={data}
                        loading={loader}
                        onPageChange={listar}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="name"
                            dataSort
                        >
                            Nombre
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            Correo
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="phone_staff"
                            dataSort
                        >
                            Tel. Personal
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="position"
                            dataSort
                        >
                            Puesto
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="company"
                            dataSort
                        >
                            Empresa
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="life_cycles"
                            dataSort
                        >
                            Ciclo de vida
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: "contacts", ver: "contacts", eliminar })}
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>

                    <LeadModal />
                </div>


            </React.Fragment>
        );
    }
}
