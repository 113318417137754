/* Actions of Industry  */

import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = 'COMPANY_SUBMIT';
const LOADER = 'COMPANY_LOADER';
const SET_DATAC = 'SET_DATAC';
const SET_PAGEC= 'SET_PAGEC';
const SET_INDUSTRY = 'SET_INDUSTRY';
const OPEN_MODAL_INDUSTRY = 'OPEN_MODAL_INDUSTRY';


export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: SET_DATAC,
    data,
});

const setPage = page => ({
    type: SET_PAGEC,
    page,
});
const setModal = stateModal => ({
    type: OPEN_MODAL_INDUSTRY,
    stateModal,
});


// ------------------------------------
// Actions
// ------------------------------------

const formatData = ( values ) => {

    return {
        name: values.name,
        description: values.description,
        phone_number: values.phone_number,
        cities: values.cities,
        employee_number: values.employee_number,
        annual_income: values.annual_income,
        industry: values.industry.value,
        linkedIn_page: values.linkedIn_page,
        facebook_page: values.facebook_page
    }

}


const onSubmit = () => (dispatch, getStore) => {
    const { values } = getStore().form.companyForm;
    console.log(values)
    const data = formatData(values)
    console.log(data)
    api.post('company', data).then((response) => {
        NotificationManager.success('Compania registrada correctamente', 'Éxito', 1000);
        dispatch(push('/company'));
    }).catch(() => {
        NotificationManager.error('Verifica si el nombre no se repite', 'ERROR', 3000);
    });
};

const detalle = id => (dispatch) => {
    dispatch(setLoader(true));

    api.get(`company/${id}`).then((response) => {
        dispatch(initializeForm('companyForm', response));
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const filterCompany = search => (dispatch) => {
    dispatch(setLoader(true));

    api.get(`company/search/${search}`).then((response) => {
        dispatch(setData(response));
    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const listar = (page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    const params = { page };

    api.get('company', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    api.eliminar(`company/${id}`).then(() => {
        NotificationManager.success('Comania eliminada correctamente', 'Éxito', 1000);
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error('Hubo error en la eliminación', 'ERROR', 0);
    });
};

const actualizar = () => (dispatch, getStore) => {
    const { values } = getStore().form.companyForm;

    const data = formatData(values)

    api.put(`company/${values.id}`, data).then(() => {
        NotificationManager.success('La compania se actualizó correctamente', 'Éxito', 1000);
        dispatch(push('/company'));
    }).catch(() => {
        NotificationManager.error('Hubo error en la actualización', 'ERROR', 0);
    });
};

const registerIndustry = () => (dispatch, getState) => {

    const formData = getState().form.createIndustry.values;
    api.post('industry', formData).then((response) => {
        NotificationManager.success('Industria registrada correctamente', 'Éxito', 1000);     
        const nuevo_elemento = {value: response.id, label: response.name}                
        dispatch(setModal(false));
        let formValues = getState().form.companyForm.values;
        formValues = !!formValues ? formValues : {};
        formValues.industry = nuevo_elemento;
        dispatch(initializeForm('companyForm', formValues));
    }).catch(() => {
        NotificationManager.error('Verifica si el nombre no se repite', 'ERROR', 3000);
    }).finally(() => {
        
    });
}

const getIndustries = ()=>()=>{

    let industries = []

    return api.get('industry').then((response)=>{
            
            response.results.forEach( lifecycle => {
                industries.push({ value: lifecycle.id, label: lifecycle.name })
            })

            return industries
        })
        .catch((err)=>{
            return industries
        })
}

const openModal = ()=>(dispatch)=>{
    dispatch( setModal(true) )
}
const closeModal = ()=>(dispatch)=>{
    dispatch( setModal(false) )
}


export const actions = {
    onSubmit,
    listar,
    detalle,
    eliminar,
    actualizar,
    filterCompany,
    registerIndustry,
    getIndustries,
    openModal,
    closeModal,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_DATAC]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_PAGEC]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SET_INDUSTRY]: (state, { industry }) => {
        return {
            ...state,
            industry,
        };
    },
    [OPEN_MODAL_INDUSTRY]: (state, { stateModal }) => {
        return {
            ...state,
            stateModal,
        };
    },        
};

export const initialState = {
    loader: false,
    data: {},
    page: 1,
    stateModal: false,
    industry: null,
};

export default handleActions(reducers, initialState);
