import React from 'react'
import currency from 'currency-formatter'

export const Cards = (props) => {

    const { meta_anual, meta_mensual, venta_semanal } = props
    return (
        <div className="uk-margin-medium-top">
        <div className="uk-flex uk-flex-center@s">

            <div className="uk-card uk-card-default uk-width-1-2@m">

                <div className="uk-card-header uk-padding-small">
                    <h6>Meta Anual</h6>
                </div>

                <div className="uk-card-body uk-padding-small">
                    <h5 className="uk-margin-remove"><span>{ currency.format( meta_anual.meta_anual , { code: 'GTQ'} ) }</span></h5>
                </div>
                
                <div className="uk-card-footer uk-padding-small">

                    <div className="uk-grid uk-grid-small uk-flex-middle">
                            <div className="uk-width-auto">
                                <h6>Lead Recibidos</h6>
                                <h5>{ meta_anual.total_lead || 0}</h5>
                            </div>
                            <div className="uk-width-expand">
                                <h6 className="">% Cierre</h6>
                                <h5>{ meta_anual.porcentaje_alcanzado || 0 } %</h5>
                            </div>
                    </div>

                </div>
            </div>

            <div className="uk-card uk-card-default uk-width-1-2@m">
                <div className="uk-card-header uk-padding-small">
                    <h6>Cumplimiento meta del mes</h6>
                </div>

                <div className="uk-card-body uk-padding-small">
                    <h5><span>{ currency.format( meta_mensual.meta_mensual , { code: 'GTQ'} )}</span></h5>
                </div>

                <div className="uk-card-footer uk-padding-small">

                    {/* REPORTE ANUAL */}
                    <div className="uk-grid uk-grid-small uk-flex-middle">
                            <div className="uk-width-auto">
                                <h6 className="uk-text-success">Alcanzado Anual</h6>
                                <h6 className="uk-text-success">{ currency.format( meta_anual.alcanzado_anual , { code: 'GTQ'} ) }</h6>
                            </div>
                            <div className="uk-width-expand">
                                <h5 className="uk-text-success">%</h5>
                                <h6 className="uk-text-success">{ meta_anual.porcentaje_alcanzado || 0 } %</h6>
                            </div>
                    </div>

                    <div className="uk-grid uk-grid-small uk-flex-middle">
                            <div className="uk-width-auto">
                                <h6 className="uk-text-danger">Restante Anual</h6>
                                <h6 className="uk-text-danger">{ currency.format( meta_anual.restante_anual, { code: 'GTQ'} ) }</h6>
                            </div>
                            <div className="uk-width-expand">
                                <h5 className="uk-text-danger">%</h5>
                                <h6 className="uk-text-danger">{ meta_anual.restante_porcentaje || 0 } %</h6>
                            </div>
                    </div>

                </div>
            </div>

            <div className="uk-card uk-card-default uk-width-1-2@m">
                <div className="uk-card-header uk-padding-small">
                    <h6>Ventas del mes</h6>
                </div>

                <div className="uk-card-body uk-padding-small">
                <h5 className="uk-text-center">{  currency.format( meta_mensual.total , { code: 'GTQ'} ) }</h5>
                </div>
            
            </div>

            <div className="uk-card uk-card-default uk-width-1-2@m">
                <div className="uk-card-header uk-padding-small">
                    <h6>Ventas de la semana</h6>
                </div>

                <div className="uk-card-body uk-padding-small">
                <h5 className="uk-text-center">{ currency.format( venta_semanal , { code: 'GTQ'} ) }</h5>
                </div>
                <div className="uk-card-footer uk-padding-small">
                    
                    <h5 className="">Alcanzado del mes</h5>
                    <h5 className="uk-text-center">{ meta_mensual.porcentaje || 0 } %</h5>

                </div>
            </div>

        </div>
        </div>
    )
}
