import React from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import {Link} from 'react-router-dom';
import { renderField, renderCurrency } from '../Utils/renderField';
import { required } from '../../../utility/validation';

const parseNumber = (value) => {
    if (value) {
        return Number(value);
    }
    return '';
};
const renderFields = ({ fields, onChangeFieldArray, ver }) => (
    <div>
        {fields.map(field => (
            <div key={`${field}.id`} className="uk-grid">
                <span className="uk-width-1-2 ">
                    <Field
                        name={`${field}.name`}
                        disabled
                        className="uk-input uk-border-rounded "
                        component={renderField}
                    />

                </span>
                <span className="uk-width-1-6">
                    <Field
                        name={`${field}.percentage`}
                        className="uk-input uk-border-rounded "
                        component={renderField}
                        type="number"
                        parse={parseNumber}
                        validate={required}
                        onChange={(e) => { onChangeFieldArray(e.target); }}
                        disabled={ver}
                    />
                </span>
                <span className="uk-width-1-3">
                    <Field
                        name={`${field}.percentage_quetzal`}
                        disabled
                        className="uk-input uk-border-rounded "
                        component={renderCurrency}
                    />
                </span>
            </div>
        ))}
    </div>
);
const FormStepTwo = (props) => {
    const { handleSubmit, previousPage, onChangeFieldArray, ver, actualizar} = props;
    return (
        <form
            onSubmit={handleSubmit}
            className="uk-card uk-card-default uk-padding uk-margin-auto"
        >
            <div className="uk-card uk-card-primary uk-width-1-1 uk-margin">
                <h3 className="uk-card-title uk-text-lead uk-text-bold title__text">
                    Lineas de Negocios
                </h3>
            </div>
            <span className="uk-grid">
                <h4 className="uk-width-1-2 uk-text-small uk-text-bold">Lineas de Negocio</h4>
                <h4 className="uk-width-1-6 uk-text-small uk-text-bold">Porcentaje</h4>
                <h4 className="uk-width-1-3 uk-text-small uk-text-bold">Equivalente en quetzales</h4>
            </span>
            <FieldArray
                name="lines"
                onChangeFieldArray={onChangeFieldArray}
                ver={ver}
                component={renderFields}
            />
            <div className="uk-flex uk-flex-between uk-margin">
                <button
                    className="uk-button uk-button-secondary uk-border-rounded uk-button-small uk-flex"
                    type="button"
                    onClick={() => previousPage()}
                >
                    Regresar
                    <i style={{marginLeft: "2px"}} className="material-icons">arrow_back</i>
                </button>

                {!ver
            && (
                <button
                    type="submit"
                    className="uk-button uk-button-primary uk-border-rounded uk-button-small uk-flex"
                >
                    {actualizar ? 'Actualizar' : 'Registrar'}
                    <i style={{marginLeft: "2px"}} className="material-icons">save</i>
                </button>
            )
                }
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'annualGoalsForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(FormStepTwo);
