import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';

import { renderField, AsyncSelectField, SelectField } from '../Utils/renderField/renderField';


import { email, phone } from '../../../utility/validation';
import EmpresaModal from './EmpresaModal';


const typeClient = [
    { value: 1, label: 'Local'},
    { value: 2, label: 'Internacional'},
];


const ContactForm = (props) => {
    const { handleSubmit, registarEmpresa, getUsuarios, getLifeCycles, getCompanies,
            getIndustries, openModal, closeModal, ver, actualizar, stateModal } = props;


    return (
        <form onSubmit={handleSubmit}>
            <div className="uk-margin-bottom">

                <div className="uk-child-width-1-2@s uk-grid">

                    <div>
                        <label>Nombre Completo</label>
                        <Field
                            name="name"
                            className="uk-input uk-border-rounded"
                            type="text"
                            component={renderField}
                            disabled={ver}
                        />
                    </div>

                    <div>
                        <label>Correo Electrónico</label>
                        <Field
                            name="email"
                            className="uk-input uk-border-rounded"
                            type="email"
                            validate={email}
                            component={renderField}
                            disabled={ver}
                        />
                    </div>

                </div>

                <div className="uk-child-width-1-2@s uk-grid">
                    <div>
                        <label>Teléfono personal</label>
                        <Field
                            name="phone_staff"
                            className="uk-input uk-border-rounded"
                            type="tel"
                            validate={phone}
                            component={renderField}
                            disabled={ver}
                        />
                    </div>

                    <div>
                        <label>Teléfono empresarial</label>
                        <Field
                            name="phone_business"
                            className="uk-input uk-border-rounded"
                            type="tel"
                            validate={phone}
                            component={renderField}
                            disabled={ver}
                        />
                    </div>
                </div>


                <div className="uk-child-width-1-2@s uk-grid">
                    <div>
                        <label>Puesto</label>
                        <Field
                            name="position"
                            className="uk-input uk-border-rounded"
                            type="text"
                            component={renderField}
                            disabled={ver}
                        />
                    </div>
                </div>

                <div className="uk-child-width-1-2@s uk-grid">
                    <div>
                        <label>Nombre Empresa</label>

                        <div>
                            <Field
                                name="company"
                                type="text"
                                component={AsyncSelectField}
                                loadOptions={getCompanies}
                                disabled={ver}
                            />
                        </div>


                        <div>
                            <button
                                className="uk-button uk-button-link uk-align-right uk-margin-remove-bottom "
                                disabled={ver}
                                type="button"
                                onClick={() => {
                                    openModal();
                                }}
                            >
                                + Empresa
                            </button>
                        </div>

                    </div>

                    <div>
                        <label>Contacto Propietario</label>
                        <Field
                            name="userowner"
                            type="text"
                            component={AsyncSelectField}
                            loadOptions={getUsuarios}
                            disabled={ver}
                        />
                    </div>
                </div>

                <div className="uk-margin-bottom uk-child-width-1-2@s uk-grid">
                    <div>
                        <label>Tipo de cliente</label>
                        <Field
                            name="client_type"
                            className="uk-input"
                            type="text"
                            component={SelectField}
                            options={typeClient}
                            disabled={ver}
                        />
                    </div>

                    <div>
                        <label>Estado del ciclo de vida</label>
                        <Field
                            name="life_cycles"
                            type="text"
                            component={AsyncSelectField}
                            loadOptions={getLifeCycles}
                            disabled={ver}
                        />
                    </div>
                </div>

                <div className="uk-flex uk-flex-center">
                    <a
                        className="uk-button uk-button-secondary uk-button-small uk-border-rounded uk-flex"
                        href="/#/contacts"
                    >

                        <i style={{marginRight: "4px"}} className="material-icons">cancel</i>
                        { ver ? 'Regresar' : 'Cancelar' }
                    </a>
                    {

                        !ver
                        && (
                            <button
                                className="uk-button uk-button-primary uk-margin-left
                                        uk-button-small uk-border-rounded uk-flex"
                                type="submit"
                            >
                                <i style={{marginRight: "4px"}} className="material-icons">save</i>
                                { actualizar ? 'Actualizar' : 'Registrar' }

                            </button>
                        )
                    }
                </div>


                <EmpresaModal
                    onSubmit={registarEmpresa}
                    stateModal={stateModal}
                    closeModal={closeModal}
                    getIndustries={getIndustries}
                />

            </div>
        </form>
    );
};

export default reduxForm({
    form: 'contactForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            name: validators.exists()('Este campo es requerido'),
            email: validators.exists()('Este campo es requerido'),
            phone_staff: validators.exists()('Este campo es requerido'),
            position: validators.exists()('Este campo es requerido'),
            company: validators.exists()('Este campo es requerido'),
            user: validators.exists()('Este campo es requerido'),
            typeclient: validators.exists()('Este campo es requerido'),
            lifecycle: validators.exists()('Este campo es requerido'),
        });
    },
})(ContactForm);
