import React from 'react';
import { Field, reduxForm, clearFields } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { api } from "api";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { renderField, AsyncSelectField, renderNumber } from '../Utils/renderField';
import CreateModal from '../Utils/renderField/createModal';
import Modal from '../Utils/Modal/ReactModal';
import BusinessLForm from '../BusinessLines/BusinessLForm';
import './index.css';

const CreateForm = (props) => {
    const { handleSubmit, ver, etapas, agregarEtapa, handleChangeItems, removeItem, handleChangeLine } = props;
    const { stateModal, openModal, closeModal, getLines, getSales } = props;
    return (
        <form onSubmit={handleSubmit} className="uk-card uk-card-default uk-padding uk-margin-auto">
            <div className="uk-grid">
                <div className="uk-width-1-2">
                    <div>
                        <label>Nombre</label>
                        <Field
                            name="name"
                            type="text"
                            component={renderField}
                            className="uk-input uk-border-rounded"
                            disabled={ver}
                        />
                        <label>Porcentaje de Linea</label>
                        <Field
                            name="percentage_line"
                            type="text"
                            component={renderNumber}
                            className="uk-input uk-border-rounded"
                            disabled
                        />
                        <label>Linea de Negocio</label>
                        <Field
                            name="business_lines"
                            type="text"
                            placeholder="Seleccionar..."
                            loadOptions={getLines}
                            component={AsyncSelectField}
                            onChange={e => handleChangeLine(e.percentage)}
                            /* className="uk-input" */
                            disabled={ver}
                        />
                        <div>
                            <button
                                disabled={ver}
                                type="button"
                                className="uk-button uk-button-link uk-align-right uk-margin-remove-bottom "
                                onClick={() => openModal()}
                            >
                                + Linea

                            </button>
                        </div>
                        <label>Canal de Venta</label>
                        <Field
                            name="sales_channel"
                            type="text"
                            placeholder="Seleccionar..."
                            loadOptions={getSales}
                            component={AsyncSelectField}
                            /* className="uk-input" */
                            disabled={ver}
                        />
                        <br />
                    </div>
                    <div className="uk-flex uk-flex-between">
                        {

                            !ver
                            && (

                                <button
                                    type="submit"
                                    className="uk-button uk-button-primary uk-button-small uk-border-rounded uk-flex"
                                >
                                    Guardar
                                    <i style={{ marginLeft: "2px" }} className="material-icons">save</i>
                                </button>

                            )
                        }

                        <a
                            className="uk-button uk-button-secondary uk-button-small uk-margin-small-left uk-border-rounded uk-flex"
                            href="/#/sales-funnel"
                            etapas={null}
                        >
                            Cancelar
                            <i style={{ marginLeft: "2px" }} className="material-icons">cancel</i>

                        </a>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <label>Etapa</label>
                    <Field
                        name="stage"
                        className="uk-input uk-border-rounded"
                        component={renderField}
                        disabled={ver}
                    />
                    <label>Porcentaje de Etapa</label>
                    <Field
                        name="percentage"
                        className="uk-input uk-border-rounded"
                        component={renderNumber}
                        disabled={ver}
                    />
                    <br />
                    {

                        !ver
                        && (
                            <button
                                type="button"
                                className="uk-button uk-button-primary uk-button-small uk-border-rounded uk-flex"
                                onClick={e => agregarEtapa()}
                            >
                                Agregar
                                <i style={{ marginLeft: "2px" }} className="material-icons">save</i>
                            </button>
                        )

                    }
                    <br />
                    <br />
                    <RLDD
                        cssClasses="example"
                        items={etapas}
                        itemRenderer={(item, index) => {
                            return (
                                <div className="itemr" key={item.id}>
                                    <p className="title">{item.name}</p>
                                    <p className="pecho">
                                        {item.percentage}
                                        %
                                    </p>
                                    <div className="small">
                                        <button
                                            className="withoutBorder"
                                            type="button"
                                            onClick={() => removeItem(item.id)}
                                        >
                                            {item.name.toLowerCase() != 'ganado' && item.name.toLowerCase() != 'perdido'
                                                &&

                                                <i className="material-icons">delete</i>
                                            }
                                        </button>
                                    </div>
                                </div>
                            );
                        }}
                        onChange={e => handleChangeItems(e)}
                    />
                </div>
            </div>
            <Modal showModal={stateModal}>
                <BusinessLForm
                    isNested
                    closeModal={closeModal}
                    onSubmit={props.funcionRegistro}
                />
            </Modal>
        </form>
    );
};


export default reduxForm({
    form: 'salesFunnelForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            /* name: validators.exists()('Este campo es requerido'),
            business_lines: validators.exists()('Este campo es requerido'),
            sales_channel: validators.exists()('Este campo es requerido'), */
        });
    },
})(CreateForm);
