
import React from 'react';
import Modal from 'react-modal';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';

import { renderField, AsyncSelectField } from '../Utils/renderField/renderField';

import './style1.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth:'400px',
        width: 'auto',
        // minWidth:'50%',
        padding: '20px',
    },
};

Modal.setAppElement('#app-container');

const EmpresaModal = (props) => {
    const closeModel = () => {

    };

    const { handleSubmit, stateModal, closeModal, getIndustries } = props;

    return (
        <Modal
            isOpen={stateModal}
            onRequestClose={closeModel}
            style={customStyles}
            // className="Modal"
            overlayClassName="Overlay"

        >
            <form onSubmit={handleSubmit}>
                <div className="uk-flex uk-flex-between">
                    <h5 className="uk-margin-right">Nueva Empresa</h5>
                    <a onClick={closeModal} className="close">x</a>
                </div>

                <div className="uk-margin-bottom">
                    <label>Nombre</label>
                    <Field
                        name="name"
                        className="uk-input uk-border-rounded"
                        type="text"
                        value=""
                        component={renderField}
                    />
                </div>

                <div className="uk-margin-medium-bottom">
                    <label>Industrias</label>
                    <Field
                        name="industry"
                        /* className='uk-input' */
                        type="text"
                        component={AsyncSelectField}
                        loadOptions={getIndustries}
                    />
                </div>


                <div className="uk-flex uk-flex-right">
                    <button
                        className="uk-button uk-button-primary uk-button-small
                                    uk-border-rounded uk-flex uk-margin-bottom"
                        type="submit"
                    >
                        <i style={{marginRight: "4px"}} className="material-icons">save</i>
                        Registrar
                    </button>
                </div>


            </form>

        </Modal>
    );
};

export default reduxForm({
    form: 'empresaModalForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            name: validators.exists()('Este campo es requerido'),
            industry: validators.exists()('Este campo es requerido'),
        });
    },
})(EmpresaModal);
