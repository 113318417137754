import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Grid from "../Utils/Grid";
import { standardActions } from "../Utils/Grid/StandardActions";


class BusinessLines extends Component {
    componentWillMount = () => {
        const { listarLinea } = this.props;

        listarLinea();
    }

    handleSearch = (e) => {
        const { listarLinea, filterBusiness } = this.props;
        if (e.target.value != '') {
            filterBusiness(e.target.value);
        } else {
            listarLinea();
        }
    }

    render() {
        const { data, loader, onSortChange, eliminarLinea, listarLinea, page } = this.props;
        return (
            <React.Fragment>
                <br />
                <h3 className="uk-text-bold uk-text-lead">Lineas de Negocio</h3>
                <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">
                    <div className="uk-flex uk-flex-between uk-padding uk-padding-remove-bottom uk-margin-auto-top@s">

                        <input
                            type="text"
                            className="uk-input uk-border-rounded uk-width-1-5"
                            onChange={this.handleSearch}
                            placeholder="Buscar..."
                        />
                        <Link
                            className="uk-button uk-button-primary uk-border-rounded uk-margin-small-bottom  uk-button-small uk-flex"
                            to="/business-lines/create"
                        >
                            Agregar
                            <i style={{marginLeft: "2px"}} className="material-icons">add_circle_outline</i>
                        </Link>

                    </div>
                    <div className="uk-padding">
                        <Grid
                            data={data}
                            loading={loader}
                            onPageChange={listarLinea}
                            onSortChange={onSortChange}
                            page={page}

                        >
                            <TableHeaderColumn
                                isKey
                                dataField="name"
                                dataSort
                            >
                                Nombre
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="description"
                                dataSort
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ editar: "business-lines", ver: "business-lines", eliminar: eliminarLinea })}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default BusinessLines;
