import {connect} from "react-redux";
import {actions} from '../../../redux/modules/annualGoals/annualGoals';
import annualGoals from './AnnualGoals';
import annualGoalsList from './AnnualGoalsList';

const ms2p = (state) => {
    return {
        ...state.annualGoals,
    };
};
const md2p = {...actions};

//= =================
// Conection List Annuals Goals
//= =================
const AnnualsGoalsList = connect(ms2p, md2p)(annualGoalsList);

//= =========================
// Conection Create Annual Goal
//= ========================?
const AnnualGoal = connect(ms2p, md2p)(annualGoals);


export const ConnectionGoals = {
    AnnualGoal,
    AnnualsGoalsList,
};
