import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {Login, Profile, Registro} from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';

import NewPassword from './common/components/LoginRegister/PasswordRecovery/NewPasswordContainer';
import VerificarToken from './VerificarToken';
// UI kit
import 'uikit/dist/css/uikit.min.css';

// Components
import reset_password from './common/components/reset_password/reset_passwordContainer';
import CrearSalesChannel from './common/components/SalesChannel/CrearSalesChannelContainer';
import SalesChannel from './common/components/SalesChannel/ListadoSalesChannelContainer';
// Change coin
import ChangeCoin from "./common/components/configurations/ChangeType/ChangeCoinContainer";
import ChangeList from "./common/components/configurations/ChangeType/ListContainer";

// Busines Lines
import BusinessLines from "./common/components/BusinessLines/ListContainer";
import CreateBusinessLines from "./common/components/BusinessLines/CreateBusinessLContainer";

import Users from "./common/components/Users/ListUsersContainer";
import CreateUser from "./common/components/Users/UsersContainer";

import { connection } from './common/components/industry/IndustryContainer';
import { contact } from './common/components/contacts/ContactContainer';
import ReportsWeek from './common/components/Reports/semanal/RerportWeekContainer';

import ChangePassword from "./common/components/LoginRegister/ChangePassword/passwordContainer";
import {ConnectionGoals} from './common/components/annualGoals/AnnualGoalsContainer';

import { connectionFunnel } from './common/components/SalesFunnel/SalesFunnelContainer';
/* import { conn2 } from './common/components/StageSale/StageSaleContainer'; */
import { connectionCompany } from './common/components/Company/CompanyContainer';
import Dashboard from './common/components/GestionLeads/GestionLeadsContainer';

import Dash from './common/components/Dashboard/DashListContainer';
import Leads from "./common/components/Reports/ListContainer"

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/registro" component={Registro} />
                <Route exact path="/reset_password" component={reset_password} />
                <VerificarToken exact path="/reset_pwd/:token" component={NewPassword} />
                <ProtectedRoute exact path="/change-password" component={ChangePassword} />

                {/* Sales Channel */}
                <ProtectedRoute exact path="/sales_channel" component={SalesChannel} />
                <ProtectedRoute exact path="/sales_channel/:id/ver" component={CrearSalesChannel} />
                <ProtectedRoute exact path="/sales_channel/:id/editar" component={CrearSalesChannel} />
                <ProtectedRoute exact path="/sales_channel/create" component={CrearSalesChannel} />

                {/* Businees Lines */}
                <ProtectedRoute exact path="/business-lines" component={BusinessLines} />
                <ProtectedRoute exact path="/business-lines/create" component={CreateBusinessLines} />
                <ProtectedRoute exact path="/business-lines/:id/ver" component={CreateBusinessLines} />
                <ProtectedRoute exact path="/business-lines/:id/editar" component={CreateBusinessLines} />

                <ProtectedRoute exact path="/" component={Demo} />
                <ProtectedRoute exact path="/page2" component={Examples} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />
                <ProtectedRoute exact path="/grids" component={Grids} />
                <ProtectedRoute exact path="/notifications" component={Notificaciones} />
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} />


                <ProtectedRoute exact path="/users" component={Users} />
                <ProtectedRoute exact path="/users/create" component={CreateUser} />
                <ProtectedRoute exact path="/users/:id/editar" component={CreateUser} />
                <ProtectedRoute exact path="/users/:id/ver" component={CreateUser} />


                {/* Industry Routes */}
                <ProtectedRoute exact path="/industry" component={connection.ListIndustry} />
                <ProtectedRoute exact path="/industry/create" component={connection.CreateIndustry} />
                <ProtectedRoute exact path="/industry/:id/editar" component={connection.CreateIndustry} />
                <ProtectedRoute exact path="/industry/:id/ver" component={connection.CreateIndustry} />

                {/* Contacts Routes */}
                <ProtectedRoute exact path="/contacts" component={contact.listContacts} />
                <ProtectedRoute exact path="/contacts/create" component={contact.createContact} />
                <ProtectedRoute exact path="/contacts/:id/editar" component={contact.createContact} />
                <ProtectedRoute exact path="/contacts/:id/ver" component={contact.createContact} />

                {/* Sales Funnel */}
                <ProtectedRoute exact path="/sales-funnel" component={connectionFunnel.ListSalesFunnel} />
                <ProtectedRoute exact path="/sales-funnel/create" component={connectionFunnel.CreateSaleFunnel} />
                <ProtectedRoute exact path="/sales-funnel/:id/editar" component={connectionFunnel.CreateSaleFunnel} />
                <ProtectedRoute exact path="/sales-funnel/:id/ver" component={connectionFunnel.CreateSaleFunnel} />

                {/* Company */}
                <ProtectedRoute exact path="/company" component={connectionCompany.ListCompanies} />
                <ProtectedRoute exact path="/company/create" component={connectionCompany.CreateCompany} />
                <ProtectedRoute exact path="/company/:id/editar" component={connectionCompany.CreateCompany} />
                <ProtectedRoute exact path="/company/:id/ver" component={connectionCompany.CreateCompany} />

                {/* Stage Sale */}
                {/* <ProtectedRoute exact path="/stage-sale" component={conn2.ListStageSale} />
                <ProtectedRoute exact path="/stage-sale/create" component={conn2.CreateStageSale} />
                <ProtectedRoute exact path="/stage-sale/:id/editar" component={conn2.CreateStageSale} />
                <ProtectedRoute exact path="/stage-sale/:id/ver" component={conn2.CreateStageSale} /> */}

                {/* Change coin routes */}
                <ProtectedRoute exact path="/config/change-coin" component={ChangeList} />
                <ProtectedRoute exact path="/config/change-coin/create" component={ChangeCoin} />
                <ProtectedRoute exact path="/config/change-coin/:id/ver" component={ChangeCoin} />
                <ProtectedRoute exact path="/config/change-coin/:id/editar" component={ChangeCoin} />

                {/* Annual Goals routes */}
                <ProtectedRoute exact path="/annual-goals/" component={ConnectionGoals.AnnualsGoalsList} />
                <ProtectedRoute exact path="/annual-goals/create" component={ConnectionGoals.AnnualGoal} />
                <ProtectedRoute exact path="/annual-goals/:id/ver" component={ConnectionGoals.AnnualGoal} />
                <ProtectedRoute exact path="/annual-goals/:id/editar" component={ConnectionGoals.AnnualGoal} />

                {/* Reportes */}
                <ProtectedRoute exact path="/reportes/leads" component= {Leads} />
                <ProtectedRoute exact path="/reportes/semanal" component= { ReportsWeek } />

                <ProtectedRoute exact path="/gestion-leads" component={Dashboard} />

                <ProtectedRoute exact path="/dashboard" component={Dash} />
                {/* Not Found */}
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
