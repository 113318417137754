import React from "react";
import './style.css';
import { Field, reduxForm } from 'redux-form';
import { AsyncSelectField } from "../Utils/renderField";
import {renderFieldRadio, renderDatePicker} from '../Utils/renderField/renderField';

const saludo =()=>{
    console.log('hola')
}

const filterForm = (props) => {
    const { handleSubmit, closeSidebar, getUsers, filterUser} = props;
    return (
        <form onSubmit={handleSubmit} className="uk-card uk-padding uk-margin-auto uk-flex-1 uk-flex-center">
            <div className="uk-flex uk-flex-between">
                <h5 className="uk-margin-right">Filtrar datos</h5>
                <a onClick={closeSidebar} className="close">x</a>
            </div>
            <div className="uk-margin-medium-bottom">
                <label>Usuario</label>
                <Field
                    name="user"
                    type="text"
                    placeholder="Seleccionar usuario"
                    component={AsyncSelectField}
                    loadOptions={getUsers}
                />
            </div>
            <div className="uk-margin-medium-bottom">
                <label>Fecha</label>
                <div className="uk-flex uk-flex-between">
                    <Field
                        type="radio"
                        name="Date"
                        label="De creacion"
                        value="created"
                        component={renderFieldRadio}
                    />
                    <Field
                        type="radio"
                        name="Date"
                        label="De ultima actividad"
                        value="activity"
                        component={renderFieldRadio}
                    />
                </div>
            </div>
            <div className="uk-flex uk-flex-between">
                <div className="uk-margin-right">
                    <label htmlFor="date_picker_field">Fecha inicial</label>
                    <Field
                        name="date_initial"
                        component={renderDatePicker}
                    />
                </div>
                <div>
                    <label htmlFor="date_picker_field">Fecha final</label>
                    <Field
                        name="date_finish"
                        component={renderDatePicker}
                    />
                </div>
            </div>
            <br />
            <div className="uk-flex uk-flex-right">
                <button
                    className="uk-button uk-button-primary uk-button-small
                                    uk-border-rounded uk-flex uk-margin-bottom"
                    type="button"
                    onClick={e => filterUser()}
                >
                    Filtrar
                    <i style={{marginLeft: "2px"}} className="material-icons">filter_list</i>
                </button>
            </div>

        </form>
    );
};

export default reduxForm({
    form: 'filterForm',

})(filterForm);
