/* Actions of Industry  */

import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import _ from 'lodash'

const SUBMIT = 'CONTACT_SUBMIT';
const LOADER = 'CONTACT_LOADER';
const SET_DATA = 'SET_DATA';
const PAGE = 'SET_PAGE';
const OPEN_MODAL = 'OPEN_MODAL';


export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: SET_DATA,
    data,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setModal = stateModal => ({
    type: OPEN_MODAL,
    stateModal,
});


// ------------------------------------
// Actions
// ------------------------------------

const typeClient = [
    { value: 1, label: 'Local'},
    { value: 2, label: 'Internacional'},
]


const formatData = ( values ) => {

    const { label } = values.client_type.value 
                    ? values.client_type 
                    : typeClient[ Number(values.client_type) - 1 || 0 ]

    return {
        name: values.name,
        email: values.email,
        phone_staff: values.phone_staff,
        phone_business: values.phone_business || '',
        position: values.position,
        client_type: label,
        company: values.company.value,
        life_cycles: values.life_cycles.value,
        userowner: values.userowner.value
    }

}

const onSubmit = () => (dispatch, getStore) => {

    const { values } = getStore().form.contactForm

    const data = formatData(values)
    
    api.post('contacts', data).then((response) => {
        NotificationManager.success('Contacto registrado correctamente', 'Éxito', 1000);
        dispatch(push('/contacts'));
    }).catch(() => {
        NotificationManager.error('Hubo error en el registro del contacto', 'ERROR', 3000);
    });
};

const detalle = id => (dispatch) => {

    dispatch(setLoader(true));

    api.get(`contacts/${id}`).then((response) => {
        
        response.client_type = {
            value: ( typeClient.findIndex( type => type.label == response.client_type ) + 1 ),
            label: response.client_type
        }

        dispatch(initializeForm('contactForm', response));

    }).catch((error) => {
        NotificationManager.error(error.detail, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

// Filtra todos los contactos a través de su nombre
const filterContact = search => (dispatch) => {

    dispatch(setLoader(true));

    api.get(`contacts/search/${search}`).then((response) => {
        dispatch(setData(response));
    }).catch((error) => {
        NotificationManager.error('Hubo error en la busqueda', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const listar = (page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    const params = { page };

    api.get('contacts', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    api.eliminar(`contacts/${id}`).then(() => {
        NotificationManager.success('El contacto se eliminó correctamente', 'Éxito', 1000);
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error('Hubo error en la eliminación', 'ERROR', 0);
    });
};

const actualizar = () => (dispatch, getStore) => {
    const { values } = getStore().form.contactForm;

    const data = formatData(values)

    api.put(`contacts/${values.id}`, data).then(() => {
        NotificationManager.success('El contacto se actualizó correctamente', 'Éxito', 1000);
        dispatch(push('/contacts'));
    }).catch(() => {
        NotificationManager.error('Hubo error en la actualización', 'ERROR', 0);
    });
};

// ===========================
//      Lista de datos para el form
// ===========================
const getUsuarios = ()=>(dispatch)=>{

    let users = []

    return api.get('user').then((response)=>{
            
           users = response.results.map( user => ({ value: user.id, label: user.username }) )
            return users
        })
        .catch((err)=>{
            return users
        })
}

const getCompanies = ()=>(dispatch)=>{

    let companies = []

    return api.get('company').then((response)=>{

           companies = response.results.map( lifecycle => ({ value: lifecycle.id, label: lifecycle.name }))
            return companies
        })
        .catch((err)=>{
            return companies
        })
}

// Lista de ciclo de vida de un lead
const getLifeCycles = ()=>(dispatch)=>{

    let lifecycles = []

    return api.get('lifecycle').then((response)=>{
            
            lifecycles = response.results.map( lifecycle => ({ value: lifecycle.id, label: lifecycle.name }) )
            return lifecycles
        })
        .catch((err)=>{
            return lifecycles
        })

}


//================================
//       Modal
//================================
const getIndustries = ()=>(dispatch)=>{

    let industries = []

    return api.get('industry').then((response)=>{
            
            industries = response.results.map( industry => ({ value: industry.id, label: industry.name }))
            return industries
        })
        .catch((err)=>{
            return industries
        })
}

// Crear una nueva empresa
const registarEmpresa = (data={})=>(dispatch, getStore )=>{
    
    data = {
        ...data,
        industry: data.industry.value
    }
    
    api.post('company', data).then((response) => {
        NotificationManager.success('La empresa se registró correctamente', 'Éxito', 1000);
        
        let formValues = _.cloneDeep( getStore().form.contactForm.values )
        formValues = !!formValues ? formValues : {}
        formValues.company = { value: response.id, label: response.name }

        if( formValues.client_type ){
            formValues.client_type = { value: formValues.client_type, 
                                        label: typeClient[ formValues.client_type - 1].label }
        }

        dispatch(initializeForm('contactForm', formValues));
        
        dispatch( setModal(false) )
    
    }).catch(() => {
        NotificationManager.error('Hubo error en el registro de la empresa', 'ERROR', 3000);
    });

}

const openModal = ()=>(dispatch)=>{
    dispatch( setModal(true) )
}
const closeModal = ()=>(dispatch)=>{
    dispatch( setModal(false) )
}

export const actions = {
    onSubmit,
    getUsuarios,
    getLifeCycles,
    getCompanies,
    getIndustries,
    listar,
    detalle,
    eliminar,
    actualizar,
    filterContact,
    openModal,
    closeModal,
    registarEmpresa
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [OPEN_MODAL]: (state, { stateModal }) => {
        return {
            ...state,
            stateModal,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    stateModal: false,
    page: 1,
};

export default handleActions(reducers, initialState);