import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "rgb(8, 112, 216)" : "rgb(30, 135, 240)",
    ...draggableStyle,
});


const Item = (props) => {
    const { lead, index, detalleLead, eliminarLead, openModal } = props;

    const eliminar = (id) => {

        Swal.fire({
            title: '¿Eliminar?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, eliminar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                eliminarLead(id)
            }
        });
    };

    return (
        <Draggable draggableId={`${lead.id}`} index={index}>
            {
                (provided, snapshot) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}
                        className="container__item"
                    >
                        <h3>
                            {lead.name}
                        </h3>
                        <p>Cierre {lead.closing_date}</p>

                        {lead.possibility_won == true
                            &&

                            <p>
                                <i
                                    className="material-icons"
                                >
                                    edit_attributes
                                </i> 
                            </p>
                        }

                        <div className="uk-align-right">
                            <a
                                onClick={() => {
                                    detalleLead(lead.id, true)
                                    openModal()
                                }}
                            >
                                <i className="material-icons" style={{ color: 'white' }}>remove_red_eye</i>
                            </a>

                            <a
                                onClick={() => {
                                    detalleLead(lead.id, false)
                                    openModal()
                                }}
                            >
                                <i className="material-icons text-warning" style={{ color: 'white' }}>edit</i>
                            </a>

                            <a
                                onClick={() => {
                                    eliminar(lead.id)
                                }}
                            ><i className="material-icons" style={{ color: '#fa8080', marginRight: '5px' }}>delete</i></a>

                        </div>

                        <p>
                            {}
                            {' '}
                            <br />
                            <br />
                            {' '}
                            {}
                        </p>

                    </div>

                )
            }

        </Draggable>

    );
};

export default Item;
