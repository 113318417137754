import React, { Component } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import Grid from '../../Utils/Grid';
import { standardActions } from '../../Utils/Grid/StandardActions';

class Changes extends Component {
    componentWillMount = () => {
        this.props.listar();
    };

    render() {
        const {
            data,
            loader,
            searchChange,
            listar,
            page,
            onSortChange,
            eliminar,
        } = this.props;
        return (
            <React.Fragment>
                <br />
                <h3 className="uk-text-bold uk-text-lead">
                    Tipos de Cambios
                </h3>
                <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">
                    <div className="uk-flex uk-flex-between uk-padding uk-padding-remove-bottom uk-margin-auto-top@s">
                        <input
                            type="text"
                            className="uk-input uk-border-rounded uk-width-1-5"
                            onChange={e => searchChange(e.target.value)
                            }
                            placeholder="Buscar..."
                        />

                        <Link
                            className="uk-button uk-button-primary uk-border-rounded uk-button-small uk-margin-small-bottom uk-flex"
                            to="/config/change-coin/create"
                        >
                            Agregar
                            <i style={{marginLeft: "2px"}} className="material-icons">add_circle_outline</i>
                        </Link>
                    </div>
                    <Grid
                        className="uk-card uk-padding"
                        data={data}
                        loading={loader}
                        onPageChange={listar}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="coin_code"
                            dataSort
                        >
                            Codigo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="coin_type"
                            dataSort
                        >
                            Moneda
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="change_type"
                            dataSort
                        >
                            Cambio
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                editar: 'change-coin',
                                ver: 'change-coin',
                                eliminar,
                            })}
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

export default Changes;
