
import React, {Component} from 'react';
import BusinessLinesForm from "./BusinessLForm";


class CreateBusinessLines extends Component{
    componentWillMount = () => {
        const {match, editarLinea} = this.props;
        if(match.params.id){
            const id = match.params.id;
            editarLinea(id);
        }
    }

    render(){
        const {match, registrarLinea, actualizarLinea, location} = this.props;
        const funcionEnvio = match.params.id ? actualizarLinea : registrarLinea;

        return(
            <div>
               <br />
               <br />
                <h2 className="uk-margin-auto uk-text-bold uk-text-lead">
                    Linea de Negocio
                </h2>
                <BusinessLinesForm
                    onSubmit={funcionEnvio}
                    actualizarLinea={match.params.id ? true : false}
                    ver={location.pathname.includes('ver')}
                />
            </div>
        )
    }
}

export default CreateBusinessLines;
