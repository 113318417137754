import React, { Component } from 'react'
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'

export default class Editor extends Component {

  // constructor(props) {
  //   super(props)
    
  //   // this.state = { text: '' } // You can also pass a Quill Delta here
  //   // this.handleChange = this.handleChange.bind(this)
  // }


  // handleChange(value) {
  //   this.setState({ text: value })
  // }
 
  render() {
    // const { text } = this.state;
    const { registrarNoticia, handleChange, textWeek } = this.props;

    return (
      <div>

        <ReactQuill 
            value={ textWeek || '' }
            onChange={handleChange}
        />
          
        <button
          className="uk-button uk-button-primary uk-button-small
                      uk-border-rounded uk-flex uk-margin-small-top"
          onClick={ ()=>{
            
            registrarNoticia( textWeek ) 
            // this.handleChange('')

          }}
        >
          <i style={{marginRight: "4px"}} className="material-icons">save</i>
          Guardar
        </button>
      </div>
    )
  }

}
