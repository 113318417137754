import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import './style.css';
import Sidebar from 'react-sidebar';
import { findLastIndex } from 'lodash';
import Column from './Columns';
import LoadMask from '../Utils/LoadMask/LoadMask';
import LeadModal from '../leads/LeadContainer';
import Select from './from';
import FilterForm from './filter';

const stylesSidebar = {
    root: {
        top: 60,
        display: 'flex',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        boxSizing: 'box-sizing',
    },
    sidebar: {
        zIndex: 2,
        transition: 'transform .3s ease-out',
        WebkitTransition: '-webkit-transform .3s ease-out',
        willChange: 'transform',
        overflowY: 'auto',
        backgroundColor: 'white',
    },
    content: {
        margin: 0,
        padding: '24px',
        paddingTop: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
        transition: 'left .3s ease-out, right .3s ease-out',
        boxSizing: 'box-sizing',
    },
};

class GestionLeads extends Component {
    componentWillMount = () => {
        const { getSalesFunnel } = this.props;
        getSalesFunnel();
    };

    componentWillUnmount = ()=>{
        const { closeModal } = this.props

        closeModal()
    }

    render() {
        const {
            columns,
            leads,
            columnsOrdering,
            onDragEnd,
            funnelOptions,
            loader,
            detalle,
            stateModal,
            openModal,
            closeModal,
            closeSidebar,
            getUsers,
            stateSidebar,
            openSidebar,
            filterUser,
            detalleLead,
            eliminarLead
        } = this.props;
        return (
            <div className="uk-flex">
                <Sidebar
                    sidebar={(
                        <FilterForm
                            closeSidebar={closeSidebar}
                            getUsers={getUsers}
                            filterUser = {filterUser}
                        />
                    )}
                    open={stateSidebar}
                    pullRight
                    styles={stylesSidebar}
                >
                    <br />
                    <span className="uk-flex uk-flex-wrap">
                        <div className="container_auto">
                            <div className="uk-flex uk-width-1-1@m">
                                <h3 className="uk-text-bold uk-text-lead uk-margin-small-right">
                                    Gestión Leads
                                </h3>
                                
                                <Select
                                    funnelOptions={funnelOptions}
                                    detalle={detalle}
                                />
                                <div className="uk-margin-small-left">
                                <button
                                        className="uk-button uk-button-primary uk-border-rounded uk-margin-small-bottom  uk-button-small uk-flex"
                                        type="button"
                                        onClick={() => {
                                            openSidebar();
                                        }}
                                    >
                                        <i
                                            style={{ marginLeft: '2px' }}
                                            className="material-icons"
                                        >
                                            filter_list
                                        </i>
                                </button>
                                </div>
                                
                            </div>
                           
                            
                            <div className="uk-flex uk-flex-middle  uk-width-1-2 justify-content-end ">
                                
                                <button
                                    className="uk-button uk-button-primary uk-border-rounded uk-margin-small-bottom uk-button-small uk-flex"
                                    type="submit"
                                    onClick={openModal}
                                >
                                    Agregar Lead
                                    <i
                                        style={{ marginLeft: '2px' }}
                                        className="material-icons"
                                    >
                                        add_circle_outline
                                    </i>
                                </button>
                            </div>
                        </div>
                    </span>
                    <LoadMask loading={loader}>
                        <div className="container_scroll">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <div className="container__flex">
                                    {columnsOrdering.map(
                                        (columnId) => {
                                            const column = columns[columnId];
                                            if (
                                                column !== undefined
                                            ) {
                                                const leadsData = column.leads_ordering.map(
                                                    leadId => leads[leadId],
                                                );
                                                return (
                                                    <Column
                                                        key={
                                                            column.id
                                                        }
                                                        column={
                                                            column
                                                        }
                                                        leads={
                                                            leadsData
                                                        }
                                                        detalleLead={ 
                                                            detalleLead
                                                        }
                                                        eliminarLead={
                                                            eliminarLead
                                                        }
                                                        openModal={
                                                            openModal
                                                        }
                                                    />
                                                );
                                            }
                                            return null;
                                        },
                                    )}
                                </div>
                            </DragDropContext>
                        </div>
                    </LoadMask>

                    <LeadModal
                        stateModal={stateModal}
                        closeModal={closeModal}
                    />
                </Sidebar>
            </div>
        );
    }
}

export default GestionLeads;
