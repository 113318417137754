import { connect } from 'react-redux'
import { actions } from  '../../../../redux/modules/reports/reportWeek'

import ReporsWeek from './ReportsWeek'
// import ReporsWeek from './Editor'

const ms2p = (state) => {
    return {
        ...state.reportWeek,
    };
};
const md2p = {...actions};


export default connect(ms2p, md2p)(ReporsWeek);