import React, { Component } from 'react';
import ContactForm from './ContactForm';


export default class Contacts extends Component {

    componentWillMount = () => {
        const {match, detalle} = this.props;
        if (match.params.id) {
            const {id} = match.params;
            detalle(id);
        }
    }

    componentWillUnmount = ()=>{
        const { closeModal } = this.props;
        closeModal()
    }

    render() {
        const { onSubmit, getUsuarios, getLifeCycles, getIndustries,
                registarEmpresa, openModal, closeModal, getCompanies,
                actualizar, location, match, stateModal } = this.props;

        const fn = match.params.id ? actualizar : onSubmit;

        return (
            <div>
                <br />
                <h3 className="uk-text-lead uk-text-bold ">Contactos </h3>
                <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto uk-margin-bottom">

                    <div>
                        <ContactForm
                            onSubmit={fn}
                            registarEmpresa={registarEmpresa}
                            getUsuarios={getUsuarios}
                            getLifeCycles={getLifeCycles}
                            getCompanies={getCompanies}
                            getIndustries={getIndustries}
                            openModal={openModal}
                            closeModal={closeModal}
                            stateModal={stateModal}
                            actualizar={!!match.params.id}
                            ver={location.pathname.includes('ver') && true}
                        />
                    </div>

                </div>
            </div>
        );
    }
}
