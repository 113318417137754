import { connect } from 'react-redux'
import { actions } from  '../../../redux/modules/industry/industry'

import IndustriesScreen from './ListIndustries'
import IndustryScreen from './Industry'

const ms2p = (state) => {
  return {
    ...state.industry,
  };
};

const md2p = { ...actions };

//===========================
// Conection List Industries
//===========================
const ListIndustry = connect(ms2p, md2p)(IndustriesScreen);
  
//==========================
// Conection Create Industry
//=========================?
const CreateIndustry = connect(ms2p, md2p)(IndustryScreen);


  export const connection = {
    ListIndustry,
    CreateIndustry
  }