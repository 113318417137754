import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    renderField,
    renderCurrency,
    renderSwitch,
} from '../Utils/renderField';
import './style.css';
import { Link } from 'react-router-dom';
import { required } from '../../../utility/validation';

const validetYear = (value) => {
    const today = new Date();
    const year = today.getFullYear();
    if (!value) {
        return "Requerido";
    }
    if (String(value).length > 4) {
        return "El ingresado año invalida";
    }
    if (parseInt(value, 10) < year) {
        return "No puede ingresar años antiguos";
    }
};
const parseNumber = (value) => {
    if (value) {
        return Number(value);
    }
    return '';
};
const FormStepOne = (props) => {
    const { handleSubmit, onChangeField, ver } = props;
    return (
        <form
            onSubmit={handleSubmit}
            className="uk-card uk-card-default uk-padding uk-margin-auto"
        >
            <div className="uk-grid uk-flex-between uk-child-width-expand">
                <div className="uk-width-1-2@s">
                    <div className="uk-card uk-card-primary">
                        <h3 className="uk-card-title uk-text-lead uk-text-bold title__text">
                            Información General
                        </h3>
                    </div>
                    <div className="uk-margin-bottom">
                        <label htmlFor="annual_goal">
                            Año de la meta
                        </label>
                        <Field
                            name="annual_goal"
                            className="uk-input uk-border-rounded"
                            component={renderField}
                            validate={validetYear}
                            type="number"
                            disabled={ver}
                        />
                    </div>
                    <div className="uk-margin-bottom">
                        <label htmlFor="goal_quetzales" />
                        Meta anual
                        <Field
                            name="goal_quetzales"
                            className="uk-input uk-border-rounded"
                            parse={parseNumber}
                            component={renderCurrency}
                            validate={required}
                            onChange={e => console.log(e)}
                            type="number"
                            disabled={ver}
                        />
                    </div>
                    <fieldset className="uk-margin-bottom uk-fieldset">
                        <Field
                            name="is_active_goal"
                            component={renderSwitch}
                            label="Activar Meta Anual (Desactivara la ultima meta activa)"
                            disabled={ver}
                        />
                    </fieldset>
                </div>
                <div className="uk-width-1-2@s">
                    <div className="uk-card uk-card-primary">
                        <h3 className="uk-card-title uk-text-lead uk-text-bold title__text">
                            Metas por Trimestre
                        </h3>
                    </div>
                    <h4 className="text__sub uk-text-bold">
                        Primer Trimestre
                    </h4>
                    <hr className="uk-margin-small" />
                    <div className="uk-grid uk-flex-between uk-margin-bottom">
                        <div className="uk-width-1-3">
                            <label htmlFor="goal_first_trimester">
                                Porcentaje
                            </label>
                            <Field
                                name="goal_first_trimester"
                                type="number"
                                parse={parseNumber}
                                className="uk-input uk-border-rounded"
                                validate={required}
                                component={renderField}
                                onChange={(e) => { onChangeField(e.target); }}
                                disabled={ver}
                            />
                        </div>
                        <div className="uk-width-expand">
                            <label htmlFor="goal_first_quetzal">
                                Equivalente en quetzales
                            </label>
                            <Field
                                name="goal_first_trimester_quetzal"
                                className="uk-input uk-border-rounded"
                                component={renderCurrency}
                                disabled
                            />
                        </div>
                    </div>
                    <h4 className="text__sub uk-text-bold">
                        Segundo Trimestre
                    </h4>
                    <hr className="uk-margin-small" />
                    <div className="uk-grid uk-flex-between uk-margin-bottom">
                        <div className="uk-width-1-3">
                            <label htmlFor="goal_second_trimester">
                                Porcentaje
                            </label>
                            <Field
                                name="goal_second_trimester"
                                type="number"
                                parse={parseNumber}
                                className="uk-input uk-border-rounded"
                                validate={required}
                                component={renderField}
                                onChange={(e) => { onChangeField(e.target); }}
                                disabled={ver}
                            />
                        </div>
                        <div className="uk-width-expand">
                            <label htmlFor="goal_second_quezal">
                                Equivalente en quetzales
                            </label>
                            <Field
                                name="goal_second_trimester_quetzal"
                                className="uk-input uk-border-rounded"
                                component={renderCurrency}
                                disabled
                            />
                        </div>
                    </div>
                    <h4 className="text__sub uk-text-bold">
                        Tercer Trimestre
                    </h4>
                    <hr className="uk-margin-small" />
                    <div className="uk-grid uk-flex-between uk-margin-bottom">
                        <div className="uk-width-1-3">
                            <label htmlFor="goal_third_trimester">
                                Porcentaje
                            </label>
                            <Field
                                name="goal_third_trimester"
                                type="number"
                                parse={parseNumber}
                                className="uk-input uk-border-rounded"
                                validate={required}
                                component={renderField}
                                onChange={(e) => { onChangeField(e.target); }}
                                disabled={ver}
                            />
                        </div>
                        <div className="uk-width-expand">
                            <label htmlFor="goal_third_quezal">
                                Equivalente en quetzales
                            </label>
                            <Field
                                name="goal_third_trimester_quetzal"
                                className="uk-input uk-border-rounded"
                                component={renderCurrency}
                                disabled
                            />
                        </div>
                    </div>
                    <h4 className="text__sub uk-text-bold">
                        Cuarto Trimestre
                    </h4>
                    <hr className="uk-margin-small" />
                    <div className="uk-grid uk-flex-between uk-margin-bottom">
                        <div className="uk-width-1-3">
                            <label htmlFor="goal_fourth_trimester">
                                Porcentaje
                            </label>
                            <Field
                                name="goal_fourth_trimester"
                                type="number"
                                className="uk-input uk-border-rounded"
                                parse={parseNumber}
                                validate={required}
                                component={renderField}
                                onChange={(e) => { onChangeField(e.target); }}
                                disabled={ver}
                            />
                        </div>
                        <div className="uk-width-expand">
                            <label htmlFor="goal_fourth_quetzal">
                                Equivalente en quetzales
                            </label>
                            <Field
                                name="goal_fourth_trimester_quetzal"
                                className="uk-input uk-border-rounded"
                                component={renderCurrency}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-flex uk-flex-between">
                <Link className="uk-button uk-button-secondary uk-border-rounded uk-button-small uk-flex" to="/annual-goals">
                    Cancelar
                    <i style={{marginLeft: "2px"}} className="material-icons">cancel</i>
                </Link>
                <button
                    className="uk-button uk-button-primary uk-border-rounded uk-button-small uk-flex"
                    type="submit"
                >
                    Siguiente
                    <i style={{marginLeft: "2px"}} className="material-icons">arrow_forward</i>
                </button>

            </div>
        </form>
    );
};

export default reduxForm({
    form: 'annualGoalsForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(FormStepOne);
