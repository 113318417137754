import React from "react";

import { Field, reduxForm } from 'redux-form';
import { AsyncSelectField } from "../Utils/renderField";
import { renderFieldRadio, renderNum, renderField } from '../Utils/renderField/renderField';

// Formulario para aplicar filtros a los leads
const filterForm = (props) => {
    const { handleSubmit, closeSidebar, getUsers, getChannels, listLeads, embudo } = props;
    return (
        <form onSubmit={handleSubmit} className="uk-card uk-padding uk-margin-auto uk-flex-1 uk-flex-center">
            <div className="uk-flex uk-flex-between">
                <h5 className="uk-margin-right">Filtrar datos</h5>
                <a onClick={closeSidebar} className="close">x</a>
            </div>
            <div className="uk-margin-medium-bottom">
                <label>Usuario</label>
                <Field
                    name="user"
                    type="text"
                    placeholder="Seleccionar..."
                    component={AsyncSelectField}
                    loadOptions={getUsers}
                />
            </div>

            <div className="uk-margin-medium-bottom">
                <label>Canal de Venta</label>
                <Field
                    name="sales_channel"
                    type="text"
                    placeholder="Seleccionar..."
                    component={AsyncSelectField}
                    loadOptions={getChannels}
                />
            </div>

            <div className="uk-margin-medium-bottom">
                <label>Rango</label>
                <div className="uk-flex uk-flex-between">
                    <Field
                        type="radio"
                        name="Date"
                        label="Por Monto"
                        value="amount"
                        component={renderFieldRadio}
                    />
                    <Field
                        type="radio"
                        name="Date"
                        label="Por Ciclo de Vida"
                        value="days"
                        component={renderFieldRadio}
                    />
                </div>
            </div>
            <div className="uk-flex uk-flex-between">
                <div className="uk-margin-right">
                    <label htmlFor="date_picker_field">Inicial</label>
                    <Field
                        name="data_initial"
                        component={renderField}
                    />
                </div>
                <div>
                    <label htmlFor="date_picker_field">Final</label>
                    <Field
                        name="data_finish"
                        component={renderField}
                    />
                </div>
            </div>
            <br></br>
            <div className="uk-flex uk-flex-right">
                <button
                    className="uk-button uk-button-primary uk-button-small
                                    uk-border-rounded uk-flex uk-margin-bottom"
                    type="button"
                    onClick={e => listLeads(embudo)}
                >
                    <i style={{ marginRight: "4px" }} className="material-icons">filter_list</i>
                    Filtrar
                </button>
            </div>

        </form>
    );
};

export default reduxForm({
    form: 'filterFormReport',

})(filterForm);