import { connect} from 'react-redux';
import { actions } from '../../../redux/modules/GestionLeads/GestionLeads';
import GestionLeads from './GestionLeads';

const ms2p = (state) => {
    return {
        ...state.gestionLeads,
    };
};

const md2p = {...actions };


export default connect(ms2p, md2p)(GestionLeads);
