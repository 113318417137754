import React, { Component } from 'react';
import CreateForm from './CreateForm';

export default class Form extends Component {
    componentWillMount = () => {
        const { match, detalle, limpiar } = this.props;
        if (match.params.id) {
            const {id} = match.params;
            detalle(id);
        } else {
            limpiar();
        }
    }
    
    componentWillUnmount = () => {
        const { closeModal } = this.props;
        closeModal()
    }

    render() {
        const {
            match,
            location,
            etapas,
            agregarEtapa,
            handleChangeItems,
            actualizar,
            onSubmit,
            removeItem,
            handleChangeLine,
        } = this.props;
        const { getLines, getSales } = this.props;
        const { openModal, closeModal, stateModal } = this.props;
        const funcionEnvio = match.params.id ? actualizar : onSubmit;
        const isActualizar = !!(match.params.id);
        return (
            <div>
                <br />
                <h2 className="uk-margin-auto uk-text-bold uk-text-lead">
                    {
                        (isActualizar)
                            ? 'Detalle Embudo de Venta'
                            : 'Crear Embudo de Venta'
                    }
                </h2>
                <CreateForm
                    ver={location.pathname.includes('ver')}
                    onSubmit={funcionEnvio}
                    etapas={etapas}
                    agregarEtapa={agregarEtapa}
                    removeItem={removeItem}
                    handleChangeItems={handleChangeItems}
                    handleChangeLine={handleChangeLine}
                    openModal={openModal}
                    closeModal={closeModal}
                    stateModal={stateModal}
                    getLines={getLines}
                    getSales={getSales}
                    funcionRegistro={this.props.registrarLineaNegocio}
                />
            </div>
        );
    }
}
