import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Grid from "../Utils/Grid";
import { standardActions } from "../Utils/Grid/StandardActions";

export default class SalesFunnel extends Component {
    componentWillMount = () => {
        const { listar } = this.props;

        listar();
    }

    handleSearch = (e) => {
        const { listar, filterSalesFunnel } = this.props;

        if (e.target.value != '') {
            filterSalesFunnel(e.target.value);
        } else {
            listar();
        }
    }


    render() {
        const { data, loader, onSortChange, eliminar, page, listar } = this.props;
        console.log(data)
        return (
            <React.Fragment>
                <br />
                <h3 className="uk-text-bold uk-text-lead">Embudos de Venta</h3>
                <div className="uk-card uk-card-default uk-padding-small uk-padding uk-margin-auto">
                    <div className="uk-flex uk-flex-between uk-padding uk-padding-remove-bottom uk-margin-auto-top@s">

                        <input
                            type="text"
                            className="uk-input uk-border-rounded uk-width-1-5"
                            onChange={this.handleSearch}
                            placeholder="Buscar..."
                        />
                        <Link
                            className="uk-button uk-button-primary uk-border-rounded uk-margin-small-bottom uk-button-small uk-flex"
                            to="/sales-funnel/create"
                        >
                            Agregar
                            <i style={{marginLeft: "2px"}} className="material-icons">add_circle_outline</i>
                        </Link>

                    </div>
                    <div className="uk-card uk-padding">
                        <Grid
                            data={data}
                            loading={loader}
                            onPageChange={listar}
                            onSortChange={onSortChange}
                            page={page}
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="name"
                                dataSort
                            >
                                Nombre
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="business_lines"
                                dataSort
                                dataFormat={(cell) => {
                                    return cell.label;
                                }}
                            >
                                Linea de Negocio
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="percentage_line"
                                dataSort
                            >
                                Porcentaje (%)
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="sales_channel"
                                dataSort
                                dataFormat={(cell) => {
                                    return cell.label;
                                }}
                            >
                                Canal de Venta
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ editar: "sales-funnel", ver: "sales-funnel", eliminar })}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
